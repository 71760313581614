<template>
  <div class="tw-grid tw-place-items-center tw-w-screen tw-h-screen">
    <div class="tw-flex tw-items-center tw-mx-auto tw-w-[90%] md:tw-w-[800px] lg:tw-w-[980px]">
      <div class="tw-w-full md:tw-flex tw-shadow-lg tw-rounded-lg">
        <div class="tw-w-full tw-bg-primary tw-hidden md:tw-block tw-rounded-l-lg">
          <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
            <img
              src="/img/logo.png"
              alt="Logo"
            >
          </div>
        </div>
        <div class="tw-w-full tw-p-12 md:tw-p-24">
          <div class="row tw-space-y-8">
            <div class="col-md-12 tw-space-y-8">
              <h2 class="subscription-form-title tw-m-0 tw-p-0">
                {{ $t('label.asoraSubscription') }}
              </h2>
              <p class="subscription-form-sub-title">
                {{ $t('strings.welcomeBack') }}
              </p>
            </div>

            <template v-if="isLoginFail">
              <div class="col-md-12">
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="alert"
                    style="margin-bottom: 0"
                    type="button"
                    @click="isLoginFail = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {{ message }}
                </div>
              </div>
            </template>

            <div class="col-md-12 tw-space-y-12">
              <div class="form-group">
                <label>{{ $t('fields.label.email') }}</label>
                <input
                  v-model="credentials.email"
                  :placeholder="$t('fields.username.placeholder')"
                  class="form-control"
                  type="text"
                >
                <span
                  v-if="validations.email"
                  class="label-error has-error"
                >
                  {{ validations['email'][0] }}
                </span>
              </div>

              <div class="form-group">
                <label>{{ $t('fields.label.password') }}</label>
                <input
                  v-model="credentials.password"
                  :placeholder="$t('fields.password.placeholder')"
                  class="form-control"
                  type="password"
                >
                <span
                  v-if="validations.password"
                  class="label-error has-error"
                >
                  {{ validations['password'][0] }}
                </span>
              </div>

              <div class="form-group">
                <button
                  class="btn btn-primary btn-block"
                  @click="onClickLogin"
                >
                  {{ $t('button.login') }}
                </button>
              </div>
            </div>

            <div class="col-md-12">
              <p class="text-center">
                <a href="https://asorasoft.com">Asorasoft</a> © {{ new Date().getFullYear() }}.
                {{ $t('strings.rightReserved') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      credentials: {
        email: '',
        password: ''
      },
      validations: {},
      isLoginFail: false,
      message: null
    }
  },
  methods: {
    onClickLogin () {
      const self = this
      this.$isLoading(true)
      this.isLoginFail = false
      this.validations = {}

      this.$axios.post('/api/auth/backend/login', this.credentials)
        .then(({ data }) => {
          if (data.data) {
            const {
              access_token,
              refresh_token,
              user,
              roles,
              permissions
            } = data.data

            localStorage.setItem(this.$token, access_token)
            localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, refresh_token)
            localStorage.setItem('user', JSON.stringify(user))

            self.$axios.defaults.headers.common.Authorization = 'Bearer ' + access_token
            self.$axios.defaults.headers.common.Accept = 'application/json'

            $.ajaxSetup({
              headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(this.$token)
              }
            })

            self.$store.dispatch('user/setUser', { user: user })
            self.$store.dispatch('user/setRoles', roles)
            self.$store.commit('user/setPermissions', permissions)

            this.$router.push({
              name: 'dashboard'
            })
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.isLoginFail = true
            this.message = error.response.data.message
            if (this.message.hasOwnProperty('message_en')) {
              this.message = this.message['message_' + this.$i18n.locale]
            }
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    onPressEnter (e) {
      if (e.code === 'Enter') {
        this.onClickLogin()
      }
    }
  },
  mounted () {
    window.addEventListener('keypress', this.onPressEnter)
  },
  beforeDestroy () {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>

<style scoped>

</style>
