<template>
  <div class="row">
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("additional.boxTitle.additionalDeviceDetail") }}
          </h3>
          <div class="box-tools">
            <BackButton button-class="btn-sm" />
          </div>
        </div>
        <div
          v-if="device"
          class="box-body"
        >
          <div
            v-if="device.icon"
            class="row"
          >
            <div class="col-md-offset-4 col-sm-8">
              <img
                :src="`${$base_api}/${device.icon}`"
                alt="thumbnail"
                class="margin-bottom"
                style="width: 60px; height: auto;"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-4 control-label">
                  {{ $t("table.name_km") }}:
                </label>
                <label class="col-md-8 control-label">
                  {{ device.name_km ? device.name_km : "-" }}
                </label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-4 control-label">
                  {{ $t("table.name_en") }}:
                </label>
                <label class="col-md-8 control-label">
                  {{ device.name_en ? device.name_en : "-" }}
                </label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-4 control-label">
                  {{ $t("table.active") }}:
                </label>
                <div class="col-md-8">
                  <template v-if="device.active">
                    <label class="control-label badge label-success">{{
                      $t("label.active")
                    }}</label>
                  </template>
                  <template v-else>
                    <label class="control-label badge label-danger">{{
                      $t("label.deactive")
                    }}</label>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-4 control-label">
                  {{ $t("table.created_date") }}:
                </label>
                <label class="col-md-8 control-label">
                  {{
                    $moment(device.created_at)
                      .locale($i18n.locale)
                      .format("LLL")
                  }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'Show',
  metaInfo: {
    title: 'Show Additional Device Detail',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    meta: [
      { charset: process.env.VUE_APP_UFT },
      { name: 'description', content: 'Show additional device detail' }
    ]
  },
  components: { BackButton },
  data () {
    return {
      device: null
    }
  },
  methods: {
    getAdditionalDeviceShow () {
      this.$isLoading(true)
      this.$axios.post('/api/backend/additional/show', {
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.device = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.getAdditionalDeviceShow()
    }
  }
}
</script>

<style scoped>

</style>
