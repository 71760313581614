<template>
  <ShopTypeForm />
</template>

<script>
import ShopTypeForm from '@/views/settings/shopType/Form'

export default {
  name: 'Create',
  components: { ShopTypeForm },
  metaInfo: {
    title: 'Create Shop Type',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    meta: [
      { charset: process.env.VUE_APP_UFT },
      { name: 'description', content: 'Create the shop type' }
    ]
  }
}
</script>

<style scoped></style>
