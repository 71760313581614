<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <template>
            <h3 class="box-title">
              {{ title }}
            </h3>
          </template>
          <div class="box-tools">
            <back-button button-class="btn-sm" />
          </div>
        </div>
        <div class="form">
          <div class="box-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-sm-12">
                    <div
                      :class="{'has-error' : validations.service_id}"
                      class="form-group"
                    >
                      <label
                        class="required"
                        for="service"
                      >{{
                        $t("plan.label.service")
                      }}</label>
                      <select
                        id="service"
                        v-model="plan.service_id"
                        class="form-control"
                      >
                        <option
                          disabled
                          value=""
                        >
                          {{ $t("plan.placeholder.selectService") }}
                        </option>
                        <template v-if="services">
                          <template v-for="(service, key) in services">
                            <option
                              :key="key"
                              :value="service.id"
                            >
                              {{ service.name }}
                            </option>
                          </template>
                        </template>
                      </select>
                      <span
                        v-if="validations.service_id"
                        class="message-validate"
                      >{{ validations.service_id[0] }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      :class="{'has-error' : validations.title}"
                      class="form-group"
                    >
                      <label
                        class="required"
                        for="planName"
                      >{{
                        $t("plan.label.planName")
                      }}</label>
                      <input
                        id="planName"
                        v-model="plan.title"
                        :placeholder="$t('plan.placeholder.inputThePlanName')"
                        class="form-control"
                        type="text"
                      >
                      <span
                        v-if="validations.title"
                        class="message-validate"
                      >{{ validations.title[0] }}</span>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      :class="{'has-error' : validations.pricing_type_id}"
                      class="form-group"
                    >
                      <label
                        class="required"
                        for="pricing_type"
                      >{{
                        $t("plan.label.pricingType")
                      }}</label>
                      <select
                        id="pricing_type"
                        v-model="selectPricingType"
                        class="form-control"
                      >
                        <option
                          :value="null"
                          disabled
                        >
                          {{ $t("plan.placeholder.selectPricingType") }}
                        </option>
                        <template v-if="pricingTypes">
                          <template
                            v-for="(item, key) in pricingTypes"
                          >
                            <option
                              :key="key"
                              :value="item"
                            >
                              {{ item["name_" + $i18n.locale] }}
                            </option>
                          </template>
                        </template>
                      </select>
                      <span
                        v-if="validations.pricing_type_id"
                        class="message-validate"
                      >{{ validations.pricing_type_id[0] }}</span>
                    </div>
                  </div>
                  <template v-if="selectPricingType && selectPricingType.has_price">
                    <div class="col-sm-6">
                      <div
                        :class="{'has-error' : validations.price}"
                        class="form-group"
                      >
                        <label
                          class="required"
                          for="price"
                        >{{
                          $t("plan.label.price")
                        }}</label>
                        <input
                          id="price"
                          v-model="plan.price"
                          :placeholder="$t('plan.placeholder.inputThePriceOfThePlan')"
                          class="form-control"
                          type="number"
                        >
                        <span
                          v-if="validations.price"
                          class="message-validate"
                        >{{ validations.price[0] }}</span>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div
                        :class="{'has-error' : validations.currency_symbol}"
                        class="form-group"
                      >
                        <label for="symbol">{{
                          $t("plan.label.currencySymbol")
                        }}</label>
                        <select
                          id="symbol"
                          v-model="plan.currency_symbol"
                          class="form-control"
                        >
                          <option
                            disabled
                            value=""
                          >
                            {{ $t("note.placeholder.selectOne") }}
                          </option>
                          <template v-if="currencySymbols">
                            <template
                              v-for="(item, key) in currencySymbols"
                            >
                              <option
                                :key="key"
                                :value="item.value"
                              >
                                {{ item.label }}
                              </option>
                            </template>
                          </template>
                        </select>
                        <span
                          v-if="validations.currency_symbol"
                          class="message-validate"
                        >{{ validations.currency_symbol[0] }}</span>
                      </div>
                    </div>
                  </template>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="order">{{
                        $t("plan.label.order")
                      }}</label>
                      <input
                        id="order"
                        v-model="plan.order"
                        :placeholder="$t('plan.placeholder.order')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="shortDescription">{{
                        $t("plan.label.shortDescription")
                      }}</label>
                      <input
                        id="shortDescription"
                        v-model="plan.short_description"
                        :placeholder="$t('plan.placeholder.shortDescription')"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="description">{{
                        $t("plan.label.description")
                      }}</label>
                      <textarea
                        id="description"
                        v-model="plan.description"
                        :placeholder="$t('plan.placeholder.description')"
                        class="form-control"
                        rows="3"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <label for="symbol">{{ $t("plan.label.highlight") }}</label>
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input
                            v-model="plan.is_popular"
                            type="checkbox"
                          >
                          {{ $t("plan.label.popular") }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="old_thumbnail"
                    class="col-md-12"
                  >
                    <label>{{ $t("plan.label.prevImage") }}</label>
                    <img
                      :src="`${$base_api}/${old_thumbnail}`"
                      class="thumbnail"
                      style="width: 120px; height: auto;"
                    >
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="thumbnail">{{
                        $t("plan.label.thumbnail")
                      }}</label>
                      <input
                        id="thumbnail"
                        accept="image/*"
                        type="file"
                        @change="onFileChange"
                      >
                    </div>
                    <div class="preview">
                      <img
                        v-if="renderImage"
                        :src="renderImage"
                        class="thumbnail"
                        style="width: 120px; height: auto;"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>{{ $t("plan.label.feature") }}</label>
                      <vue-multiselect
                        v-model="selectedFeatures"
                        :clear-on-select="false"
                        :close-on-select="false"
                        :multiple="true"
                        :options="features"
                        :preselect-first="true"
                        :preserve-search="true"
                        label="name_en"
                        placeholder="Pick some"
                        track-by="name_en"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, isOpen }"
                        >
                          <span
                            v-if="values.length &amp;&amp; !isOpen"
                            class="multiselect__single"
                          >{{ values.length }} options selected</span>
                        </template>
                      </vue-multiselect>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <template v-if="selectedFeatures.length">
                        <draggable
                          v-model="selectedFeatures"
                          class="list-group"
                          tag="ul"
                          v-bind="selectedFeatures"
                          @end="isDragging = false"
                          @start="isDragging = true"
                        >
                          <transition-group
                            name="flip-list"
                            type="transition"
                          >
                            <template v-for="element in selectedFeatures">
                              <template v-if="element.pivot">
                                <li
                                  :key="element.pivot.order"
                                  class="list-group-item"
                                >
                                  <div class="drag-item">
                                    <div class="drag-icon">
                                      -
                                    </div>
                                    <div class="drag-title">
                                      {{ element["name_" + $i18n.locale] }}
                                    </div>
                                    <div
                                      class="drag-action"
                                      @click="
                                        selectedFeatures.splice(
                                          selectedFeatures.indexOf(element),
                                          1
                                        )
                                      "
                                    >
                                      <button class="btn btn-danger btn-xs">
                                        <i class="fas fa-times-circle" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </template>
                              <template v-else>
                                <li
                                  :key="element.id"
                                  class="list-group-item"
                                >
                                  <div class="drag-item">
                                    <div class="drag-icon">
                                      -
                                    </div>
                                    <div class="drag-title">
                                      {{ element["name_" + $i18n.locale] }}
                                    </div>
                                    <div
                                      class="drag-action"
                                      @click="
                                        selectedFeatures.splice(
                                          selectedFeatures.indexOf(element),
                                          1
                                        )
                                      "
                                    >
                                      <button class="btn btn-danger btn-xs">
                                        <i class="fas fa-times-circle" />
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </template>
                            </template>
                          </transition-group>
                        </draggable>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box-footer">
            <div class="pull-right">
              <template v-if="plan_uuid">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="updatePlan(plan_uuid)"
                >
                  {{ $t("global.btn.save") }}
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-success"
                  @click="storePlan"
                >
                  {{ $t("global.btn.submit") }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'
import draggable from 'vuedraggable'

export default {
  name: 'PlanForm',
  components: { BackButton, draggable },
  computed: {
    title () {
      if (this.$route.params.uuid) {
        return this.$t('plan.boxTitle.editPlan')
      } else {
        return this.$t('plan.boxTitle.createPlan')
      }
    }
  },
  data () {
    return {
      plan_uuid: '',
      old_thumbnail: null,
      plan: {
        service_id: '',
        pricing_type_id: '',
        title: '',
        price: '',
        currency_symbol: '',
        is_popular: false,
        thumbnail: null,
        short_description: null,
        description: null,
        order: null
      },
      selectPricingType: null,
      renderImage: null,
      services: [],
      features: [],
      pricingTypes: [],
      selectedFeatures: [],
      validations: {},
      customToolbar: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline'],
        ['blockquote', 'code-block'],
        [{ script: 'sub' }, { script: 'super' }],
        [{ list: 'ordered' }, { list: 'bullet' }, 'link']
      ]
    }
  },
  methods: {
    onFileChange (e) {
      const file = e.target.files[0]
      this.plan.thumbnail = e.target.files[0]
      this.renderImage = URL.createObjectURL(file)
    },
    getServices () {
      this.$axios
        .get('/api/backend/services/get-options')
        .then((response) => {
          this.services = response.data.data
        })
    },
    getOptions () {
      this.$axios.post('/api/backend/plans/get-options')
        .then(({ data }) => {
          if (data.data) {
            this.pricingTypes = data.data.pricingTypes
            this.features = data.data.features
          }
        })
    },
    storePlan () {
      const data = new FormData()
      if (this.plan.uuid) {
        data.append('uuid', this.plan.uuid)
      }

      Object.keys(this.plan).forEach((key) => {
        if (this.plan[key] || this.plan[key] === false || this.plan[key] === true) {
          data.append(key, this.plan[key])
        } else {
          data.delete(key)
        }
      })

      if (this.selectPricingType) {
        data.append('has_price', this.selectPricingType.has_price)
        data.append('pricing_type_id', this.selectPricingType.id)
        if (this.selectPricingType.has_price) {
          data.append('price', this.plan.price)
          data.append('currency_symbol', this.plan.currency_symbol)
        }
      }

      if (this.selectedFeatures.length) {
        for (let i = 0; i < this.selectedFeatures.length; i++) {
          data.append('feature_ids[' + i + ']', this.selectedFeatures[i].id)
        }
      } else {
        data.append('feature_ids', [])
      }

      this.$isLoading(true)
      this.$axios
        .post('/api/backend/plans/store', data)
        .then(() => {
          this.$router.back()
          this.$swal({
            type: 'success',
            title: this.$t('swal.success.title.plan'),
            text: this.$t('swal.success.text.createPlan'),
            confirmButtonText: this.$t('button.ok')
          })
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getPlanInfo (uuid) {
      this.$axios
        .post('/api/backend/plans/show', {
          uuid: uuid
        })
        .then((response) => {
          this.plan = response.data.data
          if (this.plan.services && this.plan.services.length) {
            this.plan.service_id = this.plan.services[0].id
          }
          if (this.plan.features) {
            this.selectedFeatures = this.plan.features
          }
          if (this.plan.pricing_type) {
            this.selectPricingType = this.plan.pricing_type
          }
          if (this.plan.thumbnail) {
            this.old_thumbnail = this.plan.thumbnail
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    updatePlan (uuid) {
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/plans/' + uuid, this.plan)
        .then((response) => {
          if (response.data.code === 200) {
            this.$router.back()
            this.$swal({
              type: 'success',
              title: this.$t('swal.success.title.plan'),
              text: this.$t('swal.success.text.updatePlan')
            })
          } else {
            if (response.data.hasOwnProperty('validate')) {
              this.validations = response.data.validate
            } else {
              this.$swal({
                type: 'error',
                title: this.$t('swal.success.title.plan'),
                text: response.data.message,
                confirmButtonColor: this.$config.light_gray
              })
            }
          }
        })
    }
  },
  mounted () {
    this.getServices()
    this.getOptions()
    if (this.$route.params.uuid) {
      this.plan.service_uuid = this.$route.params.uuid
    }
    if (this.$route.params.uuid) {
      this.getPlanInfo(this.$route.params.uuid)
    }
  }
}
</script>

<style scoped>
.preview img {
  max-width: 100%;
  max-height: 500px;
}

.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}
</style>
