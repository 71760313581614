<template>
  <div v-if="client">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ title }}
        </h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr v-if="client.code">
                  <td>{{ $t("subscription.table.subscriptionCode") }}</td>
                  <td>{{ client.code }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.clientName") }}</td>
                  <td>{{ client.name }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.phone") }}</td>
                  <td>{{ client.phone }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.shopName") }}</td>
                  <td>{{ client.shop_name }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.shopType") }}</td>
                  <td>
                    {{
                      client.shop_type
                        ? client.shop_type["name_" + $i18n.locale]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.plan") }}</td>
                  <td>{{ client.plan ? client.plan.title : "-" }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.price") }}</td>
                  <td>
                    <template v-if="client.plan">
                      <template v-if="client.plan.pricing_type">
                        <template v-if="client.plan.pricing_type.has_price">
                          {{ client.plan.show_price }}
                          {{ client.plan.currency_symbol }}
                        </template>
                        <template v-else>
                          {{ client.plan.pricing_type["name_" + $i18n.locale] }}
                        </template>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.status") }}</td>
                  <td>
                    <template v-if="client.final_status">
                      <span class="badge">{{ client.final_status }}</span>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.homeNumber") }}</td>
                  <td>{{ client.home_number ? client.home_number : "-" }}</td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.streetNumber") }}</td>
                  <td>
                    {{ client.street_number ? client.street_number : "-" }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.village") }}</td>
                  <td>
                    {{
                      client.village
                        ? client.village["name_" + $i18n.locale]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.commune") }}</td>
                  <td>
                    {{
                      client.commune
                        ? client.commune["name_" + $i18n.locale]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.district") }}</td>
                  <td>
                    {{
                      client.district
                        ? client.district["name_" + $i18n.locale]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.province") }}</td>
                  <td>
                    {{
                      client.province
                        ? client.province["name_" + $i18n.locale]
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.additionals") }}</td>
                  <td>
                    <ul v-if="client.additionals && client.additionals.length">
                      <li
                        v-for="(item, key) in client.additionals"
                        :key="key"
                      >
                        {{ item["name_" + $i18n.locale] }}
                      </li>
                    </ul>
                    <template v-else>
                      -
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("client_request.table.clientRequestNote") }}</td>
                  <td>{{ client.note ? client.note : "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("client_request.boxTitle.shopLocation") }}
        </h3>
        <div class="box-tools">
          <button
            class="btn btn-default btn-sm"
            :title="$t('client_request.boxTitle.shopLocation')"
            @click="clientLocation"
          >
            <i class="fa fa-compass" />
          </button>
          <button
            class="btn btn-default btn-sm"
            @click="showMap"
          >
            <i
              class="fa"
              :class="show_map ? 'fa-angle-up' : 'fa-angle-down'"
            />
          </button>
        </div>
      </div>
      <div
        class="box-body"
        v-if="show_map"
      >
        <div
          class="row"
          v-if="client_location"
        >
          <div class="col-md-12">
            <GmapMap
              ref="gMap"
              :options="map_options"
              :center="map_center"
              :zoom="18"
              map-type-id="terrain"
              style="width: 100%; height: 500px"
              @center_changed="updateMapCenter"
              @idle="sync"
            >
              <GmapMarker
                :position="client_location"
                :clickable="false"
                :draggable="false"
              />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionClient',
  props: {
    title: {
      type: String,
      default: null
    },
    client: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      show_map: false,
      location: null,
      client_location: null,
      map_center: null,
      map_options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }
    }
  },
  methods: {
    showMap () {
      this.show_map = !this.show_map
      if (this.client) {
        this.client_location = {
          lat: this.client.lat,
          lng: this.client.lng
        }
      }
      this.clientLocation()
    },
    updateMapCenter (latLgn) {
      this.location = {
        lat: latLgn.lat(),
        lng: latLgn.lng()
      }
    },
    sync () {
      this.map_center = this.location
    },
    clientLocation () {
      if (this.client_location) {
        this.location = {
          lat: this.client_location.lat,
          lng: this.client_location.lng
        }
        this.sync()
      }
    }
  }
}
</script>

<style scoped></style>
