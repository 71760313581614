<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <template>
        <h3 class="box-title">
          {{ title }}
        </h3>
      </template>
      <div class="box-tools">
        <BackButton button-class="btn-sm" />
      </div>
    </div>
    <div class="form">
      <div class="box-body">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-horizontal">
              <div
                :class="{'has-error' : validations.name_km}"
                class="form-group"
              >
                <label
                  class="col-sm-3 control-label required"
                  for="name_km"
                >
                  {{ $t("table.name_km") }}
                </label>
                <div class="col-sm-9">
                  <input
                    id="name_km"
                    v-model="device.name_km"
                    :placeholder="$t('table.name_km')"
                    class="form-control"
                    type="text"
                  >
                  <span
                    v-if="validations.name_km"
                    class="message-validate"
                  >{{ validations.name_km[0] }}</span>
                </div>
              </div>

              <div
                :class="{'has-error' : validations.name_en}"
                class="form-group"
              >
                <label
                  class="col-sm-3 control-label required"
                  for="name_en"
                >
                  {{ $t("table.name_en") }}</label>
                <div class="col-sm-9">
                  <input
                    id="name_en"
                    v-model="device.name_en"
                    :placeholder="$t('table.name_en')"
                    class="form-control"
                    type="text"
                  >
                  <span
                    v-if="validations.name_en"
                    class="message-validate"
                  >{{ validations.name_en[0] }}</span>
                </div>
              </div>

              <div class="form-group">
                <label
                  class="col-sm-3 control-label"
                  for="thumbnail"
                >
                  {{ $t("plan.label.thumbnail") }}
                </label>
                <div class="col-sm-9">
                  <input
                    id="thumbnail"
                    accept="image/*"
                    class="margin-bottom"
                    type="file"
                    @change="onFileChange"
                  >
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="preview">
                        <img
                          v-if="previewImage"
                          :src="previewImage"
                          class="thumbnail"
                        >
                      </div>
                    </div>

                    <div
                      v-if="device.icon"
                      class="col-sm-12"
                    >
                      <label>{{ $t("plan.label.prevImage") }}</label>
                      <div class="row">
                        <div class="col-xs-6 col-md-4 col-lg-3">
                          <div class="thumbnail">
                            <img
                              :src="`${$base_api}/${device.icon}`"
                              width="100%"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="col-sm-offset-3 col-sm-9">
                  <template v-if="device.uuid">
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="store"
                    >
                      {{ $t("global.btn.save") }}
                    </button>
                  </template>
                  <template v-else>
                    <button
                      class="btn btn-success"
                      @click="store"
                    >
                      {{ $t("global.btn.submit") }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'AdditionalDeviceForm',
  components: { BackButton },
  data () {
    return {
      validations: {},
      icon: null,
      previewImage: null,
      device: {
        uuid: null,
        name_km: null,
        name_en: null,
        active: null,
        icon: null
      }
    }
  },
  computed: {
    title () {
      if (this.$route.params.uuid) {
        return this.$t('plan.boxTitle.editAdditionalDevice')
      } else {
        return this.$t('plan.boxTitle.createAdditionalDevice')
      }
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.getAdditionalDeviceShow()
    }
  },
  methods: {
    onFileChange (e) {
      const file = e.target.files[0]
      this.previewImage = URL.createObjectURL(file)
      this.icon = file
    },
    getAdditionalDeviceShow () {
      this.$axios
        .post('/api/backend/additional/show', {
          uuid: this.$route.params.uuid
        })
        .then((res) => {
          this.device = res.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    store () {
      this.validations = {}
      const formData = new FormData()

      if (this.device.name_en) {
        formData.append('name_km', this.device.name_km)
      }
      if (this.device.name_en) {
        formData.append('name_en', this.device.name_en)
      }
      formData.append('active', this.device.active)
      if (this.device.uuid) {
        formData.append('uuid', this.device.uuid)
      }
      if (this.icon) {
        formData.append('icon', this.icon)
      }

      this.$isLoading(true)
      this.$axios.post('/api/backend/additional/store', formData)
        .then(({ data }) => {
          if (data.data) {
            this.$router.push({
              name: 'list-additional-device'
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.thumbnail {
  width: 100%;
}

.preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
