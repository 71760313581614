<template>
  <ShopTypeForm />
</template>

<script>
import ShopTypeForm from '@/views/settings/shopType/Form'

export default {
  name: 'Edit',
  components: { ShopTypeForm },
  metaInfo () {
    return {
      title: this.$t('page.editShopType'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped></style>
