import { generatePhpArray } from '@/composables/permission/convertPhpArray'

const Permissions = {
  backend: {
    name: {
      en: 'Backend',
      km: 'ផ្ទាំងក្រោយ'
    },
    manage: 'manage-backend',
    dashboard: {
      name: {
        en: 'Dashboard',
        km: 'ផ្ទាំងគ្រប់គ្រង'
      },
      manage: 'manage-dashboard',
      viewCountsWidget: 'view-counts-widget-dashboard',
      viewExpiredWidget: 'view-expired-widget-dashboard',
      viewDueWidget: 'view-due-widget-dashboard'
    },
    clientRequest: {
      name: {
        en: 'Client Request',
        km: 'សំណើអតិថិជន'
      },
      manage: 'manage-client-request',
      list: 'list-client-request',
      show: 'show-client-request',
      store: 'store-client-request',
      edit: 'edit-client-request',
      delete: 'delete-client-request'
    },
    payment: {
      name: {
        en: 'Payment',
        km: 'ទូទាត់ប្រាក់'
      },
      manage: 'manage-payment',
      list: 'list-payment',
      show: 'show-payment',
      store: 'store-payment',
      edit: 'edit-payment',
      delete: 'delete-payment'
    },
    customer: {
      name: {
        en: 'Customer',
        km: 'អតិថិជន'
      },
      manage: 'manage-customer',
      list: 'list-customer',
      show: 'show-customer',
      store: 'store-customer',
      edit: 'edit-customer',
      delete: 'delete-customer'
    },
    changePlanRequest: {
      name: {
        en: 'Change Plan Request',
        km: 'ប្តូរគម្រោង'
      },
      manage: 'manage-change-plan-request',
      list: 'list-change-plan-request',
      show: 'show-change-plan-request',
      store: 'store-change-plan-request',
      edit: 'edit-change-plan-request',
      delete: 'delete-change-plan-request',
      reject: 'reject-change-plan-request',
      approve: 'approve-change-plan-request'
    },
    renewalPlanRequest: {
      name: {
        en: 'Renewal Plan Request',
        km: 'សំណើបន្តគម្រោង'
      },
      manage: 'manage-renewal-plan-request',
      list: 'list-renewal-plan-request',
      show: 'show-renewal-plan-request',
      store: 'store-renewal-plan-request',
      edit: 'edit-renewal-plan-request',
      delete: 'delete-renewal-plan-request',
      reject: 'reject-renewal-plan-request',
      approve: 'approve-renewal-plan-request'
    },
    service: {
      name: {
        en: 'Service',
        km: 'គម្រោង'
      },
      manage: 'manage-service',
      list: 'list-service',
      show: 'show-service',
      store: 'store-service',
      edit: 'edit-service',
      delete: 'delete-service',
      listTrashed: 'list-trashed-service',
      restore: 'restore-service',
      refreshSecret: 'refresh-secret-service'
    },
    settings: {
      name: {
        en: 'Settings',
        km: 'ការកំណត់'
      },
      manage: 'manage-settings',
      plan: {
        name: {
          en: 'Plan',
          km: 'គម្រោង'
        },
        manage: 'manage-plan',
        list: 'list-plan',
        show: 'show-plan',
        store: 'store-plan',
        edit: 'edit-plan',
        delete: 'delete-plan',
        restore: 'restore-plan',
        listTrashed: 'list-trashed-plan'
      },
      feature: {
        name: {
          en: 'Feature',
          km: 'មុខងារ'
        },
        manage: 'manage-feature',
        list: 'list-feature',
        show: 'show-feature',
        store: 'store-feature',
        edit: 'edit-feature',
        delete: 'delete-feature',
        toggle: 'toggle-feature'
      },
      shopType: {
        name: {
          en: 'Shop Type',
          km: 'ប្រភេទហាង'
        },
        manage: 'manage-shop-type',
        list: 'list-shop-type',
        show: 'show-shop-type',
        store: 'store-shop-type',
        edit: 'edit-shop-type',
        delete: 'delete-shop-type',
        toggle: 'toggle-shop-type'
      },
      additionalDevice: {
        name: {
          en: 'Additional Device',
          km: 'ឧបករណ៌បន្ថែម'
        },
        manage: 'manage-additional-device',
        list: 'list-additional-device',
        show: 'show-additional-device',
        store: 'store-additional-device',
        edit: 'edit-additional-device',
        delete: 'delete-additional-device',
        toggle: 'toggle-additional-device'
      },
      logsViewer: {
        name: {
          en: 'Logs Viewer',
          km: 'កំណត់ហេតុបញ្ហា'
        },
        manage: 'manage-logs-viewer',
        list: 'list-logs-viewer',
        show: 'show-logs-viewer',
        store: 'store-logs-viewer',
        edit: 'edit-logs-viewer',
        delete: 'delete-logs-viewer'
      },
      gender: {
        name: {
          en: 'Gender',
          km: 'ភេទ'
        },
        manage: 'manage-gender',
        list: 'list-gender'
      }
    },
    administration: {
      name: {
        en: 'Administration',
        km: 'រដ្ឋបាល'
      },
      manage: 'manage-administration',
      user: {
        name: {
          en: 'User',
          km: 'អ្នកប្រើប្រាស់'
        },
        manage: 'manage-user',
        list: 'list-user',
        show: 'show-user',
        store: 'store-user',
        edit: 'edit-user',
        changePassword: 'change-user-password'
      },
      role: {
        name: {
          en: 'Role',
          km: 'តួនាទី'
        },
        manage: 'manage-role',
        list: 'list-role',
        show: 'show-role',
        store: 'store-role',
        edit: 'edit-role',
        delete: 'delete-role'
      },
      permission: {
        name: {
          en: 'Permission',
          km: 'សិទ្ធិ'
        },
        manage: 'manage-permission',
        list: 'list-permission'
      }
    }
  }
}

export const copyPermission = () => {
  const textArea = document.createElement('textarea')
  textArea.value = generatePhpArray()
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export default Permissions
