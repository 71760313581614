import { datepickerLocale } from '@/composables/datepickerLocale'
import { currencySymbols } from '@/composables/currencySymbols'
import { mapState } from 'vuex'
import Permissions from '@/permissions'

const helpers = {
  data () {
    return {
      currencySymbols,
      datepickerLocale,

      administrators: [
        process.env.VUE_APP_ROLE_ADMIN,
        'admin'
      ],

      avatar: '/img/avatar.png',
      $Permissions: Permissions
    }
  },
  computed: {
    ...mapState({
      userRoles: state => state.user.data ? state.user.data.roles : null,
      userPermissions: state => state.user.data ? state.user.data.permissions : null
    }),
    baseUrl () {
      return process.env.VUE_APP_API
    }
  },
  methods: {
    notEmptyObject (someObject) {
      return Object.keys(someObject).length > 0
    },

    concatImg (src) {
      if (src) {
        return this.$base_api + '/' + src
      }
      return ''
    },

    checkImageUrl (src) {
      if (src) {
        if (src.includes('https://')) {
          return src
        } else {
          return this.concatImg(src)
        }
      }
      return ''
    },

    onResponseError (error) {
      if (error.statusText) {
        this.$toastr('error', error.statusText, this.$t('string.error'))
      } else if (error.response) {
        if (error.response.status === 403) {
          this.$toastr(
            'error',
            error.response.data.message,
            this.$t('string.error')
          )
        } else if (error.response.status === 401) {
          this.$toastr(
            'error',
            error.response.data.message,
            this.$t('string.error')
          )
          localStorage.clear()
          this.$store.commit('user/clearUser')
        } else {
          try {
            if (
              error.response.data.message &&
              error.response.data.message['message_' + this.$i18n.locale]
            ) {
              this.$toastr(
                'error',
                error.response.data.message['message_' + this.$i18n.locale],
                this.$t('string.error')
              )
            } else {
              this.$toastr(
                'error',
                error.response.statusText,
                this.$t('string.error')
              )
            }
          } catch (e) {
            this.$toastr(
              'error',
              this.$t('string.somethingWentWrong'),
              this.$t('string.error')
            )
          }
        }
      }
      if (error.response === undefined) {
        this.$toastr(
          'error',
          this.$t('string.somethingWentWrong'),
          'Undefined'
        )
      }
    },
    checkValidations (model, key = '') {
      if (model) {
        return model.hasOwnProperty(key)
      }
      return false
    },
    getDateFormat (date, format = process.env.VUE_APP_DATE_FORMAT) {
      try {
        if (this.$moment(date, 'YYYY-MM-DD hh:mm:ss').isValid()) {
          return this.$moment(date, 'YYYY-MM-DD hh:mm:ss').format(format)
        }
        return this.$t('string.na')
      } catch (error) {
        return date
      }
    },
    getSrc (url) {
      if (!url) {
        return ''
      } else if (url.startsWith('http')) {
        return url
      } else if (url.startsWith('/')) {
        return process.env.VUE_APP_API + url
      } else {
        return process.env.VUE_APP_API + '/' + url
      }
    },
    onConfirm (options) {
      return new Promise((resolve, reject) => {
        this.$swal(
          Object.assign(
            {
              title: this.$t('string.areYouSure?'),
              text: this.$t('string.youWontBeAbleToRevertThis!'),
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3a7afe',
              cancelButtonColor: '#ed524f',
              cancelButtonText: this.$t('button.no'),
              confirmButtonText: this.$t('button.yes')
            },
            options
          )
        )
          .then((result) => {
            if (result.value) {
              resolve({
                accept: true
              })
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                accept: false
              })
            }
          })
      })
    },
    can (permissions) {
      if (!permissions || permissions.length === 0 || this.$store.getters['user/isAdmin']) {
        return true
      }

      if (!this.userPermissions) {
        return false
      }

      return this.userPermissions.some(permission => permissions.includes(permission))
    },
    hasRoles (roles) {
      return this.$store.getters['user/isAdmin'] || this.onlyHasRoles(roles)
    },
    onlyHasRoles (roles) {
      if (!roles || roles.length === 0) {
        return true
      }

      if (!this.userRoles) {
        return false
      }

      return this.userRoles.some(role => roles.includes(role))
    },
    isAdmin () {
      if (!this.userRoles) {
        return false
      }
      return this.userRoles.some(role => this.administrators.includes(role))
    },

    getUserInfo () {
      const self = this
      this.$axios.post('/api/backend/user/get-roles-and-permissions')
        .then(async (response) => {
          const result = response.data.data
          await self.$store.dispatch('user/setUser', { user: result.user })
          // store roles
          self.$store.commit('user/setRoles', result.roles)

          // store permission
          self.$store.commit('user/setPermissions', result.permissions)
        }).catch(error => {
          self.onResponseError(error)
        })
    },
    clearEventHandler (classNames) {
      classNames.map((className) => {
        return $(document).off('click', className)
      })
    },
    getName (object) {
      if (object) {
        if (object.hasOwnProperty('name_' + this.$i18n.locale)) {
          return object['name_' + this.$i18n.locale]
        } else if (object.hasOwnProperty('name')) {
          return object.name
        } else if (object.hasOwnProperty('title')) {
          return object.title
        }
      }
      return this.$t('label.na')
    },
    showSwalSuccess () {
      this.$swal(this.$t('label.swal.deleteLabel'), this.$t('label.swal.success'), 'success')
    },
    showToastr () {
      this.$toastr('success', this.$t('string.theRequestHaveBeenProcessed'), this.$t('string.success'))
    }
  }
}

export default {
  install (Vue) {
    Vue.mixin(helpers)
  }
}
