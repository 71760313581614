<template>
  <section class="content-header">
    <h1>
      <template>{{ config.VUE_APP_NAME }}</template>
      <small>{{ config.VUE_APP_DESC }}</small>
    </h1>
    <ol
      v-if="$route.meta.breadcrumb"
      class="breadcrumb"
    >
      <li>
        <router-link
          :to="{name: 'dashboard'}"
        >
          <i class="fas fa-tachometer-alt" />
          {{ $t('menu.dashboard') }}
        </router-link>
      </li>
      <li
        v-for="(step, index) in $route.meta.breadcrumb"
        :key="index"
        :class="{active: index === $route.meta.breadcrumb.length - 1}"
      >
        <template v-if="step.route">
          <router-link :to="step.route">
            {{ getName(step) }}
          </router-link>
        </template>
        <template v-else>
          <span>{{ getName(step) }}</span>
        </template>
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  name: 'Breadcrumb',
  data () {
    return {
      config: process.env
    }
  }
}
</script>

<style scoped>

</style>
