<template>
  <div class="col-md-12">
    <h3>{{ $t("logs_viewer.string.logs") }}</h3>
    <div class="box box-primary">
      <div class="box-body">
        <div class="table-responsive mb-3">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th class="text-left">
                  <span class="label label-info">{{
                    $t("logs_viewer.date")
                  }}</span>
                </th>
                <th class="text-center">
                  <span class="level level-all">
                    <i class="fa fa-fw fa-list" />
                    {{ $t("logs_viewer.level.all") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-emergency">
                    <i class="fa fa-fw fa-bug" />
                    {{ $t("logs_viewer.level.emergency") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-alert">
                    <i class="fa fa-fw fa-bullhorn" />
                    {{ $t("logs_viewer.level.alert") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-critical">
                    <i class="fa fa-fw fa-heartbeat" />
                    {{ $t("logs_viewer.level.critical") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-error">
                    <i class="fa fa-fw fa-times-circle" />
                    {{ $t("logs_viewer.level.error") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-warning">
                    <i class="fa fa-fw fa-exclamation-triangle" />
                    {{ $t("logs_viewer.level.warning") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-notice">
                    <i class="fa fa-fw fa-exclamation-circle" />
                    {{ $t("logs_viewer.level.notice") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-info">
                    <i class="fa fa-fw fa-info-circle" />
                    {{ $t("logs_viewer.level.info") }}
                  </span>
                </th>
                <th class="text-center">
                  <span class="level level-debug">
                    <i class="fa fa-fw fa-life-ring" />
                    {{ $t("logs_viewer.level.debug") }}
                  </span>
                </th>
                <th class="text-right">
                  {{ $t("logs_viewer.string.actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="logs && Object.keys(logs).length > 0">
                <tr
                  v-for="(log, logkey) in logs"
                  :key="`tr${logkey}`"
                >
                  <td
                    v-for="(value, key) in log"
                    :key="`${key}-${value}`"
                    :class="{
                      'text-left': key === 'date',
                      'text-center': key !== 'date',
                    }"
                  >
                    {{ value }}
                  </td>
                  <td class="text-right">
                    <router-link
                      :to="{
                        name: 'show-logs-viewer',
                        params: {
                          date: log['date'],
                        },
                      }"
                      class="btn btn-xs btn-info margin-right-5"
                    >
                      <i class="fa fa-search" />
                    </router-link>
                    <button
                      class="btn btn-xs btn-success margin-right-5"
                      @click="downloadLog(log['date'])"
                    >
                      <i class="fa fa-download" />
                    </button>
                    <button
                      class="btn btn-xs btn-danger"
                      @click="deleteLog(log['date'])"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td
                    class="text-center"
                    colspan="11"
                  >
                    <span class="badge badge-secondary">
                      {{ $t("logs_viewer.string.the_list_of_logs_is_empty") }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="totalPage > 1">
          <paginate
            :click-handler="getList"
            :container-class="'pagination m-0'"
            :next-text="'Next'"
            :page-class="'page-item'"
            :page-count="totalPage"
            :prev-text="'Prev'"
            next-link-class="page-link"
            page-link-class="page-link"
            prev-link-class="page-link"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LogsList',
  data () {
    return {
      logs: [],
      totalPage: 0,
      page: 1
    }
  },
  methods: {
    getList (page = 1) {
      this.page = page
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/logs-viewer/list', {
          page: this.page
        })
        .then(({ data }) => {
          if (data.data) {
            this.logs = data.data.data
            this.totalPage = data.data.total_pages
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    deleteLog (date) {
      this.onConfirm().then((accept) => {
        if (accept) {
          this.validations = {}
          this.$isLoading(true)
          this.$axios.post('/api/backend/logs-viewer/delete', {
            date
          }).then(() => {
            this.getList()
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    downloadLog (date) {
      this.$axios({
        method: 'POST',
        url: self.$base_api + '/api/backend/logs-viewer/download',
        params: { date },
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `laravel-${date}.log`)
        document.body.appendChild(link)
        link.click()
      })
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped>
.stack-content {
  padding: 8px;
  color: #ae0e0e;
  font-family: consolas, Menlo, Courier, monospace;
  font-size: 12px;
  font-weight: 400;
  white-space: pre-line;
}

.info-box.level {
  display: block;
  padding: 0;
  margin-bottom: 15px;
  min-height: 70px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.info-box.level .info-box-text,
.info-box.level .info-box-number,
.info-box.level .info-box-icon > i {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.info-box.level .info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box.level .info-box-content {
  padding: 5px 10px;
  margin-left: 70px;
}

.info-box.level .info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.info-box.level .info-box-icon {
  border-radius: 2px 0 0 2px;
  display: block;
  float: left;
  height: 70px;
  width: 70px;
  text-align: center;
  font-size: 40px;
  line-height: 70px;
  background: rgba(0, 0, 0, 0.2);
}

.info-box.level .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}

.info-box.level .progress .progress-bar {
  background: #fff;
}

.info-box.level-empty {
  opacity: 0.6;
  filter: grayscale(1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: -webkit-filter, opacity;
  -moz-transition-property: -moz-filter, opacity;
  -o-transition-property: filter, opacity;
  transition-property: -webkit-filter, -moz-filter, -o-filter, filter, opacity;
}

.info-box.level-empty:hover {
  opacity: 1;
  filter: grayscale(0);
}

.level {
  padding: 2px 6px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-size: 0.9em;
  font-weight: 600;
}

.badge.level-all,
.badge.level-emergency,
.badge.level-alert,
.badge.level-critical,
.badge.level-error,
.badge.level-warning,
.badge.level-notice,
.badge.level-info,
.badge.level-debug,
.level,
.level i,
.info-box.level-all,
.info-box.level-emergency,
.info-box.level-alert,
.info-box.level-critical,
.info-box.level-error,
.info-box.level-warning,
.info-box.level-notice,
.info-box.level-info,
.info-box.level-debug {
  color: #fff;
}

.label-env {
  font-size: 0.85em;
}

.badge.level-all,
.level.level-all,
.info-box.level-all {
  background-color: #8a8a8a;
}

.badge.level-emergency,
.level.level-emergency,
.info-box.level-emergency {
  background-color: #b71c1c;
}

.badge.level-alert,
.level.level-alert,
.info-box.level-alert {
  background-color: #d32f2f;
}

.badge.level-critical,
.level.level-critical,
.info-box.level-critical {
  background-color: #f44336;
}

.badge.level-error,
.level.level-error,
.info-box.level-error {
  background-color: #ff5722;
}

.badge.level-warning,
.level.level-warning,
.info-box.level-warning {
  background-color: #ff9100;
}

.badge.level-notice,
.level.level-notice,
.info-box.level-notice {
  background-color: #4caf50;
}

.badge.level-info,
.level.level-info,
.info-box.level-info {
  background-color: #1976d2;
}

.badge.level-debug,
.level.level-debug,
.info-box.level-debug {
  background-color: #90caf9;
}

.badge.level-empty,
.level.level-empty {
  background-color: #d1d1d1;
}

.badge.label-env,
.label.label-env {
  background-color: #6a1b9a;
}
</style>
