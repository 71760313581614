import Vue from 'vue'
import VueI18n from 'vue-i18n'

const files = require.context('./locale/', false, /\.json$/i)
const messages = {}
files.keys().forEach((key) => {
  const lang = key.split('/')[1].split('.')[0]
  messages[lang] = files(key)
})

Vue.use(VueI18n)

let locale = process.env.VUE_APP_LOCALE
if (localStorage.getItem('locale')) {
  if (localStorage.getItem('locale') === 'km' || localStorage.getItem('locale') === 'en') {
    locale = localStorage.getItem('locale')
  }
}

export const i18n = new VueI18n({
  locale,
  messages
})
