<template>
  <div class="row">
    <div class="col-lg-3 col-xs-6">
      <div class="small-box bg-green">
        <div class="inner">
          <h3>{{ customer }}</h3>
          <p>{{ $t("string.totalCustomers") }}</p>
        </div>
        <div class="icon">
          <i class="fa fa-chalkboard-teacher" />
        </div>
        <router-link
          :to="{ name: 'list-customer' }"
          class="small-box-footer"
        >
          {{ $t("label.moreInfo") }}
          <i class="fa fa-arrow-circle-right" />
        </router-link>
      </div>
    </div>
    <div class="col-lg-3 col-xs-6">
      <div class="small-box bg-teal">
        <div class="inner">
          <h3>{{ clientRequests }}</h3>
          <p>{{ $t("string.totalClients") }}</p>
        </div>
        <div class="icon">
          <i class="fa fa-address-book" />
        </div>
        <router-link
          :to="{ name: 'client-request-index' }"
          class="small-box-footer"
        >
          {{ $t("label.moreInfo") }}
          <i class="fa fa-arrow-circle-right" />
        </router-link>
      </div>
    </div>
    <div class="col-lg-3 col-xs-6">
      <div class="small-box bg-aqua">
        <div class="inner">
          <h3>{{ services }}</h3>
          <p>{{ $t("string.totalServices") }}</p>
        </div>
        <div class="icon">
          <i class="fa fa-book" />
        </div>
        <router-link
          :to="{ name: 'list-service' }"
          class="small-box-footer"
          href="#"
        >
          {{ $t("label.moreInfo") }}
          <i class="fa fa-arrow-circle-right" />
        </router-link>
      </div>
    </div>
    <div class="col-lg-3 col-xs-6">
      <div class="small-box bg-blue">
        <div class="inner">
          <h3>USD {{ earns }}</h3>
          <p>{{ $t("string.totalEarns") }}</p>
        </div>
        <div class="icon">
          <i class="fa fa-boxes" />
        </div>
        <router-link
          :to="{ name: 'dashboard-earn' }"
          class="small-box-footer"
        >
          {{ $t("label.moreInfo") }}
          <i class="fa fa-arrow-circle-right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsCount',
  data () {
    return {
      customer: 0,
      clientRequests: 0,
      services: 0,
      earns: 0
    }
  },
  methods: {
    getStatsCount () {
      this.$isLoading(true)
      this.$axios.post('/api/backend/dashboard/get-stats-count ')
        .then(({ data }) => {
          if (data.data) {
            const response = data.data
            this.customer = response.customer
            this.clientRequests = response.clientRequests
            this.services = response.services
            this.earns = response.earns
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    this.getStatsCount()
  }
}
</script>

<style scoped>

</style>
