<template>
  <AdditionalDeviceForm />
</template>

<script>
import AdditionalDeviceForm from '@/views/settings/additionalDevice/Form'

export default {
  name: 'Edit',
  components: { AdditionalDeviceForm },
  metaInfo: {
    title: 'Edit Additional Device',
    titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    meta: [
      { charset: process.env.VUE_APP_UFT },
      { name: 'description', content: 'Edit the additional device' }
    ]
  }
}
</script>

<style scoped></style>
