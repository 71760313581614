<template>
  <FeatureForm />
</template>

<script>
import FeatureForm from '@/views/settings/feature/Form'

export default {
  name: 'Create',
  components: { FeatureForm },
  metaInfo () {
    return {
      title: this.$t('page.createNewFeature'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped></style>
