<template>
  <div class="wrapper">
    <Header />
    <Navbar />
    <div class="content-wrapper">
      <Breadcrumb />
      <section class="content container-fluid">
        <router-view />
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import Navbar from '@/components/Navbar'
import Header from '@/components/Header'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  name: 'Primary',
  components: {
    Breadcrumb,
    Header,
    Navbar,
    Footer
  },
  mounted () {
    $('body').resize()
  }
}
</script>

<style scoped>

</style>
