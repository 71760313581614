export const state = () => {
  return {
    duration: null,
    nextRenewalDate: null
  }
}

export const getters = {
  duration: state => state.duration,
  nextRenewalDate: state => state.nextRenewalDate
}

export const mutations = {
  setDuration (state, duration) {
    state.duration = duration
  },

  setNextRenewalDate (state, nextRenewalDate) {
    state.nextRenewalDate = nextRenewalDate
  }
}

export const actions = {
  setDuration ({ commit }, duration) {
    commit('setDuration', duration)
  },

  setNextRenewalDate ({ commit }, nextRenewalDate) {
    commit('setNextRenewalDate', nextRenewalDate)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
