<template>
  <div
    class="user-panel"
    v-if="user"
  >
    <div class="pull-left image">
      <template v-if="user.avatar_location">
        <img
          :src="`${$base_api}/${user.avatar_location}`"
          class="img-circle"
          alt="User Image"
        >
      </template>
      <template v-else>
        <img
          :src="avatar"
          class="img-circle"
          alt="User Image"
        >
      </template>
    </div>
    <div class="pull-left info">
      <p>{{ fullName }}</p>
      <a href="/">
        <i class="fa fa-circle text-success" /> {{ $t("label.online") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavbarUser',
  computed: {
    ...mapState({
      user: (state) => state.user.data
    }),
    fullName () {
      if (this.user) {
        return `${this.user.first_name} ${this.user.last_name}`
      }
      return this.$t('string.unknown')
    }
  }
}
</script>

<style scoped></style>
