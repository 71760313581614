<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.nextRenewal') }}
      </h3>
      <div class="box-tools">
        <BackButton />
      </div>
    </div>
    <div class="box-body">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>{{ $t('table.date') }}</th>
            <th>{{ $t('table.price') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="customer">
            <td>{{ $moment(customer.next_renewal_date).format('DD MMM, YYYY') }}</td>
            <td>${{ parseFloat(customer.next_renewal_price).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BackButton from '@/components/BackButton'

export default {
  name: 'CustomerRenewalList',
  components: { BackButton },
  computed: {
    ...mapGetters('customer', {
      customer: 'customer'
    })
  }
}
</script>

<style scoped>

</style>
