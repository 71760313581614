export const useBaseRouteModules = () => {
  const baseAdminModuleUrl = '/admin/administrations'
  const baseSettingModuleUrl = '/admin/settings'
  const baseAccountModuleUrl = '/admin'
  const baseDashboardModuleUrl = '/admin/dashboard'
  const baseRenewalPlanModuleUrl = '/admin/renewal-plan-request'
  const baseChangePlanModuleUrl = '/admin/change-plan-request'
  const baseCustomerModuleUrl = '/admin/customer'
  const baseServiceModuleUrl = '/admin/service'
  const basePaymentModuleUrl = '/admin/payment'
  const baseClientRequestModuleUrl = '/admin/client-request'

  return {
    baseAdminModuleUrl,
    baseSettingModuleUrl,
    baseAccountModuleUrl,
    baseDashboardModuleUrl,
    baseRenewalPlanModuleUrl,
    baseChangePlanModuleUrl,
    baseCustomerModuleUrl,
    baseServiceModuleUrl,
    basePaymentModuleUrl,
    baseClientRequestModuleUrl
  }
}
