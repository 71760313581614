<template>
  <div
    v-if="client"
    id="show-client-request"
    ref="section"
    class="row"
  >
    <div class="col-md-5">
      <div class="client-request">
        <SectionClient
          :client="client"
          :title="$t('client_request.boxTitle.showClient')"
        />
      </div>
    </div>
    <div class="col-md-7">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ form_title }}
          </h3>
          <div class="box-tools">
            <template v-if="show_form">
              <template v-if="payment && form_type === 'mark_paid'">
                <button
                  class="btn btn-info btn-sm"
                  @click="showPayment(payment.uuid)"
                >
                  <i class="fas fa-edit" /> {{ $t("global.btn.edit") }}
                </button>
              </template>
              <button
                class="btn btn-default btn-sm"
                @click="clickCancel"
              >
                <i class="fas fa-times-circle" /> {{ $t("btn.cancel") }}
              </button>
            </template>
            <template v-else>
              <button
                class="btn btn-primary btn-sm"
                @click="showHideForm(true, 'create', $t('label.addPayment'))"
              >
                <i class="fas fa-plus-circle" /> {{ $t("label.addPayment") }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <template v-if="show_form">
            <template v-if="form_type === 'create'">
              <PaymentForm
                :client-request-uuid="client.uuid"
                :payment="payment"
                @callBackFunction="getClient"
              />
            </template>
            <template v-else-if="form_type === 'mark_paid'">
              <MarkAsPaidForm
                :payment="payment"
                @callBackFunction="getClient"
              />
            </template>
          </template>
          <template v-else>
            <template v-if="payments.length">
              <template v-for="(item, key) in payments">
                <PaymentItem
                  :key="key"
                  :item="item"
                  :payment-item-class="
                    item.is_paid ? 'payment-item-paid' : 'payment-item-no-paid'
                  "
                  @clickButtonDelete="deletePayment(item.uuid)"
                  @clickButtonEdit="showPayment(item.uuid)"
                  @clickButtonPaid="showPayment(item.uuid, true)"
                />
              </template>
            </template>
            <template v-else>
              <img-no-data />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionClient from '@/components/SectionClient'
import PaymentForm from '@/views/payment/_components/PaymentForm'
import PaymentItem from '@/views/payment/_components/PaymentItem'
import ImgNoData from '@/components/ImgNoData'
import MarkAsPaidForm from '@/views/payment/_components/MarkAsPaidForm'

export default {
  name: 'Show',
  metaInfo () {
    return {
      title: this.$t('page.paymentDetail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: {
    MarkAsPaidForm,
    ImgNoData,
    PaymentItem,
    PaymentForm,
    SectionClient
  },
  data () {
    return {
      payments: [],
      client: null,
      form_title: this.$t('label.payment'),
      show_form: false,
      payment: null,
      form_type: null
    }
  },
  mounted () {
    this.getClient()
    this.getPaymentByClient()
  },
  methods: {
    clickCancel () {
      this.show_form = false
      this.form_title = this.$t('label.payment')
      this.payment = null
      this.form_type = null
    },
    showHideForm (show_from, form_type, title = '', is_edit = false) {
      if (!is_edit) this.payment = null
      this.show_form = show_from
      this.form_title = title
      this.form_type = form_type
    },
    getClient () {
      this.showHideForm(false, null, this.$t('label.payment'))
      this.$axios
        .post('/api/backend/registration-request/show', {
          uuid: this.$route.params.uuid
        })
        .then((res) => {
          this.client = res.data.data
          this.getPaymentByClient(1)
        })
    },
    getPaymentByClient (page = 1) {
      this.$axios
        .post('/api/backend/payment/get-payment-by-client', {
          client_request_uuid: this.$route.params.uuid,
          page: page
        })
        .then((res) => {
          this.payments = res.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    showPayment (uuid, is_paid = false) {
      this.$axios
        .post('/api/backend/payment/show', {
          uuid: uuid
        })
        .then((res) => {
          this.payment = res.data.data
          if (is_paid) {
            this.showHideForm(
              true,
              'mark_paid',
              'payment.label.paymentInvoice',
              true
            )
          } else {
            this.showHideForm(true, 'create', 'Edit invoice payment', true)
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    deletePayment (uuid) {
      const self = this
      self
        .$swal({
          type: 'warning',
          title: self.$t('swal.delete.title.deleteInvoice'),
          text: self.$t('swal.delete.text.deleteInvoice'),
          showCancelButton: true,
          confirmButtonColor: '#a5a6ac',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.$t('global.btn.deleteIt'),
          cancelButtonText: this.$t('global.btn.noDelete')
        })
        .then((result) => {
          if (result.value) {
            this.$axios.post('/api/backend/payment/delete', {
              uuid: uuid
            }).then(() => {
              self.$swal({
                title: self.$t('label.progress'),
                text: self.$t('swal.delete.text.deletedInvoice'),
                type: 'success'
              })
              this.getClient()
            }).catch((error) => {
              this.onResponseError(error)
            })
          }
        })
    }
  }
}
</script>

<style scoped></style>
