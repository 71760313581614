<template>
  <div>
    <template v-if="allowAccess">
      <template v-if="can([$Permissions.backend.dashboard.viewCountsWidget])">
        <StatsCount />
      </template>
      <template v-if="can([$Permissions.backend.dashboard.viewExpiredWidget])">
        <ExpiredList />
      </template>
      <template v-if="can([$Permissions.backend.dashboard.viewDueWidget])">
        <DueList />
      </template>
    </template>
    <template v-else>
      <Welcome />
    </template>
  </div>
</template>

<script>
import StatsCount from '@/views/dashboard/includes/StatsCount'
import ExpiredList from '@/views/dashboard/includes/ExpiredList'
import Welcome from '@/views/dashboard/includes/Welcome'
import DueList from '@/views/dashboard/includes/DueList'

export default {
  name: 'Dashboard',
  metaInfo () {
    return {
      title: this.$t('string.dashboard'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: {
    DueList,
    Welcome,
    ExpiredList,
    StatsCount
  },
  computed: {
    allowAccess () {
      return this.can([
        this.$Permissions.backend.dashboard.viewCountsWidget,
        this.$Permissions.backend.dashboard.viewExpiredWidget
      ])
    }
  }
}
</script>

<style scoped>

</style>
