<template>
  <div class="client-request">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("client_request.boxTitle.listOfClientRequest") }}
        </h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <table
              id="client-request-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("client_request.table.clientName") }}</th>
                  <th>{{ $t("client_request.table.phone") }}</th>
                  <th>{{ $t("client_request.table.shopName") }}</th>
                  <th>{{ $t("client_request.table.shopType") }}</th>
                  <th>{{ $t("client_request.table.plan") }}</th>
                  <th>{{ $t("client_request.table.province") }}</th>
                  <th>{{ $t("client_request.table.createdDate") }}</th>
                  <th>{{ $t("client_request.table.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientRequestIndex',
  metaInfo () {
    return {
      title: this.$t('page.clientRequestList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.getClientRequest()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    getClientRequest () {
      const self = this
      return $('#client-request-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        autoWidth: false,
        ajax: {
          url: self.$base_api + '/api/backend/registration-request/datatable',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
          }
        },
        columns: [
          {
            data: 'name',
            name: 'name',
            title: this.$t('client_request.table.clientName')
          },
          {
            data: 'phone',
            name: 'phone',
            title: this.$t('client_request.table.phone')
          },
          {
            data: 'shop_name',
            name: 'shop_name',
            title: this.$t('client_request.table.shopName')
          },
          {
            data: 'shop_type_name',
            name: 'shop_type_name',
            title: this.$t('client_request.table.shopType'),
            searchable: false
          },
          {
            data: 'plan_name',
            name: 'plan_name',
            title: this.$t('client_request.table.plan'),
            searchable: false
          },
          {
            data: 'province_name',
            name: 'province_name',
            title: this.$t('client_request.table.province'),
            searchable: false
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('client_request.table.createdDate')
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('client_request.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[6, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show-plan')

      $(document).on('click', '.btn-show-plan', function (e) {
        e.preventDefault()
        self.$router.push({
          name: 'client-request-show',
          params: {
            uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.getClientRequest()
    this.buttonAction()
  }
}
</script>

<style scoped></style>
