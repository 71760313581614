<template>
  <div class="plan">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("plan.boxTitle.listOfPlan") }}
        </h3>
        <div class="box-tools">
          <BackButton />
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <table
              id="plan-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("plan.table.name") }}</th>
                  <th>{{ $t("plan.table.price") }}</th>
                  <th>{{ $t("plan.table.duration") }}</th>
                  <th>{{ $t("plan.table.createdDate") }}</th>
                  <th>{{ $t("plan.table.deletedDate") }}</th>
                  <th>{{ $t("plan.table.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'Trash',
  metaInfo () {
    return {
      title: this.$t('page.planTrashedList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { BackButton },
  data () {
    return {
      oTable: null,
      Plans: []
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.getPlans()
      this.oTable.draw(true)
    },
    oTable: function () {
      this.buttonAction()
    }
  },
  methods: {
    getPlans () {
      const self = this
      return $('#plan-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/plans/trash',
          type: 'POST'
        },
        columns: [
          { data: 'name', name: 'name', title: this.$t('plan.table.name') },
          { data: 'price', name: 'price', title: this.$t('plan.table.price') },
          {
            data: 'duration_as_second',
            name: 'duration_as_second',
            title: this.$t('plan.table.duration')
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('plan.table.createdDate'),
            searchable: false
          },
          {
            data: 'deleted_at',
            name: 'deleted_at',
            title: this.$t('plan.table.deletedDate'),
            searchable: false
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('plan.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        columnDefs: [{ width: '5%', targets: [0, 1, 2, 3, 4, 5] }]
      })
    },
    restorePlan (uuid) {
      const self = this
      self
        .$swal({
          type: 'info',
          title: self.$t('swal.restore.title.restorePlan'),
          text: self.$t('swal.restore.text.restorePlan'),
          showCancelButton: true,
          confirmButtonColor: '#f39c12',
          cancelButtonColor: this.$config.light_gray,
          confirmButtonText: this.$t('global.btn.yesRestore'),
          cancelButtonText: this.$t('global.btn.noNeed')
        })
        .then((result) => {
          if (result.value) {
            const data = {
              uuid: uuid
            }
            this.$axios
              .post('/api/backend/plans/restore', data)
              .then((response) => {
                if (response.data.code === 200) {
                  self.$router.back()
                  self.$swal({
                    title: self.$t('label.plan'),
                    text: self.$t('swal.success.text.restorePlan'),
                    type: 'success'
                  })
                }
              })
          }
        })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-restore-plan')

      $(document).on('click', '.btn-restore-plan', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')
        self.restorePlan(uuid)
      })
    }
  },
  mounted () {
    this.oTable = this.getPlans()
    this.buttonAction()
  }
}
</script>

<style scoped></style>
