<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        Earns
      </h3>
      <div class="box-tools">
        <BackButton />
      </div>
    </div>
    <div class="box-body">
      <UnderDevelopment
        :box="false"
        height="height: 72vh"
      />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'
import UnderDevelopment from '@/components/UnderDevelopment'

export default {
  name: 'Earn',
  metaInfo () {
    return {
      title: this.$t('string.earns'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { UnderDevelopment, BackButton }
}
</script>

<style scoped>

</style>
