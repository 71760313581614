import { userRouters } from './user'
import { roleRouters } from './role'
import { permissionRouters } from './permission'
import Permissions from '@/permissions'

export const administrationRouters = {
  path: 'administration',
  name: 'administration',
  component: () => import('@/views/administration/Index'),
  redirect: 'user',
  meta: {
    permissions: [Permissions.backend.administration.manage]
  },
  children: [
    userRouters,
    roleRouters,
    permissionRouters
  ]
}
