import Permissions from '@/permissions'
import { planRouters } from '@/router/settings/planRouters'
import { featureRouters } from '@/router/settings/featureRouters'
import { genderRouters } from '@/router/settings/gender'
import { shopTypeRouters } from '@/router/settings/shopTypeRouters'
import { additionalDeviceRouters } from '@/router/settings/additionalDeviceRouters'
import { logsViewerRouters } from '@/router/settings/logs'

export const settingsRouters = {
  name: 'settings',
  path: 'settings',
  component: () => import('@/views/settings/Index'),
  redirect: 'plan',
  meta: {
    permissions: [Permissions.backend.settings.manage]
  },
  children: [
    planRouters,
    featureRouters,
    shopTypeRouters,
    additionalDeviceRouters,
    logsViewerRouters,
    genderRouters
  ]
}
