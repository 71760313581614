<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t("string.customerList") }}
      </h3>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <table
            id="customer-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("table.name") }}</th>
                <th>{{ $t("table.email") }}</th>
                <th>{{ $t("table.phone") }}</th>
                <th>{{ $t("table.service") }}</th>
                <th>{{ $t("table.currentPlan") }}</th>
                <th>{{ $t("table.created_at") }}</th>
                <th>{{ $t("table.actions") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo () {
    return {
      title: this.$t('string.customerList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $('#customer-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/customer/datatable',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
          }
        },
        columns: [
          {
            data: 'name',
            name: 'name',
            title: this.$t('table.name')
          },
          {
            data: 'email',
            name: 'email',
            title: this.$t('table.email'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'phone',
            name: 'phone',
            title: this.$t('table.phone'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'service.name',
            name: 'service.name',
            title: this.$t('table.service'),
            orderable: false
          },
          {
            data: 'current_plan.title',
            name: 'currentPlan.title',
            title: this.$t('table.currentPlan'),
            orderable: false
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('table.created_date')
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('client_request.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[5, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show')

      $(document).on('click', '.btn-show', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')

        self.$router.push({
          name: 'show-customer',
          params: {
            customer_uuid: uuid
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
