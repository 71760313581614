<template>
  <service-form />
</template>

<script>
import ServiceForm from './Form.vue'

export default {
  name: 'Edit',
  metaInfo () {
    return {
      title: this.$t('page.editService'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { ServiceForm }
}
</script>

<style scoped></style>
