import FeatureLayout from '@/views/settings/feature/Index'
import FeatureIndex from '@/views/settings/feature/List'
import FeatureCreate from '@/views/settings/feature/Create'
import FeatureEdit from '@/views/settings/feature/Edit'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.settings.feature

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'feature',
    breadcrumb: [
      {
        name_en: 'Settings',
        name_km: 'ការកំណត់'
      },
      {
        name_en: 'Feature',
        name_km: 'មុខងារ'
      },
      link
    ]
  }
}

export const featureRouters = {
  path: 'feature',
  component: FeatureLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'feature'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/feature`,
      name: 'feature-index',
      component: FeatureIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'feature-create',
      component: FeatureCreate,
      meta: getMeta([permissions.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:uuid',
      name: 'feature-edit',
      component: FeatureEdit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    }
  ]
}
