import ClientRequestLayout from '@/views/clientRequest/Index'
import ClientRequestIndex from '@/views/clientRequest/List'
import ClientRequestShow from '@/views/clientRequest/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseClientRequestModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.clientRequest

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'clientRequest',
    breadcrumb: [
      {
        name_en: 'Client Request',
        name_km: 'សំណើអតិថិជន'
      },
      link
    ]
  }
}

export const clientRequestRouters = {
  path: 'client-request',
  component: ClientRequestLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'clientRequest'
  },
  children: [
    {
      path: `${baseClientRequestModuleUrl}`,
      name: 'client-request-index',
      component: ClientRequestIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:uuid',
      name: 'client-request-show',
      component: ClientRequestShow,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
