<template>
  <div>
    <template v-if="box">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
        </div>
        <div class="box-body">
          <div
            class="under-development"
            :style="`${height}`"
          >
            <div>
              <p class="text-center">
                <img
                  :src="svg"
                  style="width: 30%;"
                  class="margin-bottom"
                  alt=""
                >
              </p>
              <h3 class="font-km-bold text-center font-secondary">
                {{ description }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="under-development"
        :style="`${height}`"
      >
        <div>
          <p class="text-center margin-bottom">
            <img
              :src="svg"
              style="width: 30%;"
              alt=""
            >
          </p>
          <h3 class="font-km-bold text-center font-secondary">
            {{ description }}
          </h3>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UnderDevelopment',
  props: {
    box: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    description: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    svg: {
      type: String,
      default: '/img/svg/development.svg'
    },
    height: {
      type: String,
      default: 'height: 80vh;'
    }
  }
}
</script>

<style scoped>
.under-development {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
