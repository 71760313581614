import Vue from 'vue'

const state = {
  data: null,
  administrators: [
    process.env.VUE_APP_ROLE_ADMIN,
    'admin'
  ]
}

const getters = {
  avatar: (state) => {
    if (state.data && state.data.avatar_location) {
      return state.data.avatar_location
    }
    return null
  },
  getUser: (state) => {
    return state.data
  },
  isAdmin: (state) => {
    if (state.data && state.data.roles && Array.isArray(state.data.roles)) {
      return state.data.roles.some(role => state.administrators.includes(role))
    } else {
      return false
    }
  }
}

const mutations = {
  setUser (state, data) {
    if (!state.data) {
      Vue.set(state, 'data', {})
    }
    for (const attribute of Object.keys(data.user)) {
      if (!['roles', 'permissions'].includes(attribute)) {
        Vue.set(state.data, attribute, data.user[attribute])
      }
    }
  },
  setRoles (state, roles) {
    if (state.data) {
      state.data.roles = roles
    }
  },
  setPermissions (state, permissions) {
    if (state.data) {
      state.data.permissions = permissions
    }
  },

  clearUser (state) {
    state.data = null
  },

  setAvatar (state, avatar) {
    if (state.data && state.data.avatar) {
      state.data.avatar = avatar
    }
  }
}

const actions = {
  setUser ({ commit }, data) {
    commit('setUser', data)
  },
  setRoles ({ commit }, data) {
    commit('setRoles', data)
  },
  setPermissions ({ commit }, data) {
    commit('setPermissions', data)
  },
  clearUser ({ commit }) {
    commit('clearUser')
  },
  setAvatar ({ commit }, avatar) {
    commit('setAvatar', avatar)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
