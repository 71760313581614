import Index from '@/views/customer/Index'
import List from '@/views/customer/List'
import Show from '@/views/customer/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseCustomerModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.customer

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'customer',
    breadcrumb: [
      {
        name_en: 'Customer',
        name_km: 'អតិថិជន'
      },
      link
    ]
  }
}

export const customerRouters = {
  path: 'customer',
  component: Index,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'customer'
  },
  children: [
    {
      path: `${baseCustomerModuleUrl}`,
      name: 'list-customer',
      component: List,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:customer_uuid',
      name: 'show-customer',
      component: Show,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
