<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group">
        <label
          class="required"
          for="total_price"
        >{{
          $t("payment.label.totalPrice")
        }}</label>
        <div class="input-group custom-input-group">
          <input
            id="total_price"
            v-model="total_price"
            :class="{
              'input-danger': checkValidations(validations, 'total_price'),
            }"
            :placeholder="$t('payment.placeholder.totalPrice')"
            class="form-control"
            type="number"
          >
          <select
            id="symbol"
            v-model="currency_symbol"
            :class="{
              'input-danger': checkValidations(validations, 'total_price'),
            }"
            class="form-control"
          >
            <option
              disabled
              value=""
            >
              {{ $t("note.placeholder.selectOne") }}
            </option>
            <template v-for="(item, key) in currencySymbols">
              <template v-if="currencySymbols">
                <option
                  :key="key"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </template>
            </template>
          </select>
        </div>
        <span
          v-if="checkValidations(validations, 'total_price')"
          class="message-validate"
        >
          {{ validations.total_price[0] }}
        </span>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-group">
        <label
          class="required"
          for="duration"
        >{{
          $t("payment.label.duration")
        }}</label>
        <div class="input-group custom-input-group">
          <input
            id="duration"
            v-model="duration"
            :class="{
              'input-danger': checkValidations(validations, 'duration'),
            }"
            :placeholder="$t('payment.placeholder.duration')"
            class="form-control"
            type="number"
          >
          <select
            v-model="duration_type"
            :class="{
              'input-danger': checkValidations(validations, 'duration'),
            }"
            class="form-control"
            data-live-search="true"
            title="Please select a lunch ..."
          >
            <option
              :value="null"
              disabled
            >
              {{ $t("note.placeholder.selectOne") }}
            </option>
            <template v-for="(item, key) in duration_types">
              <template v-if="duration_types.length">
                <option
                  :key="key"
                  :value="item.value"
                >
                  {{ item["name_" + $i18n.locale] }}
                </option>
              </template>
            </template>
          </select>
        </div>
        <span
          v-if="checkValidations(validations, 'duration')"
          class="message-validate"
        >
          {{ validations.duration[0] }}
        </span>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="form-group">
        <label
          class="required"
          for="start_date"
        >{{
          $t("payment.label.startDate")
        }}</label>
        <date-picker
          id="start_date"
          v-model="start_date"
          :class="{
            'mx-datepicker-error': checkValidations(validations, 'start_date'),
          }"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('note.placeholder.selectDate')"
          format="YYYY-MM-DD"
          type="date"
          value-type="format"
        />
        <span
          v-if="checkValidations(validations, 'start_date')"
          class="message-validate"
        >
          {{ validations.start_date[0] }}
        </span>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="form-group">
        <label
          class="required"
          for="end_date"
        >{{
          $t("payment.label.endDate")
        }}</label>
        <date-picker
          id="end_date"
          v-model="end_date"
          :class="{
            'mx-datepicker-error': checkValidations(validations, 'end_date'),
          }"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('note.placeholder.selectDate')"
          format="YYYY-MM-DD"
          type="date"
          value-type="format"
        />
        <span
          v-if="checkValidations(validations, 'end_date')"
          class="message-validate"
        >
          {{ validations.end_date[0] }}
        </span>
      </div>
    </div>
    <div
      v-if="additionals.length"
      class="col-sm-12"
    >
      <div class="form-group">
        <label>{{ $t("label.otherDevices") }}</label>
        <div
          v-for="(item, key) in additionals"
          :key="key"
          class="form-group"
        >
          <div class="checkbox">
            <label>
              <input
                v-model="selected_devices"
                :value="item.id"
                type="checkbox"
              >
              <img
                :alt="item['name_' + $i18n.locale]"
                :src="`${$base_api}/${item.icon}`"
                class="additional-item-icon"
              >
              {{ item["name_" + $i18n.locale] }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-group">
        <label for="contact_note">{{ $t("note.label.note") }}</label>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input
                v-model="active"
                type="checkbox"
              >
              {{ $t("payment.label.active") }}
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <label><input
              v-model="is_paid"
              type="checkbox"
            >{{
              $t("payment.label.isPaid")
            }}</label>
          </div>
        </div>
        <template v-if="is_paid">
          <div class="form-group">
            <label
              class="required"
              for="cashier"
            >{{
              $t("payment.label.cashier")
            }}</label>
            <select
              id="cashier"
              v-model="cashier_id"
              :class="{
                'input-danger': checkValidations(validations, 'cashier_id'),
              }"
              class="form-control"
              name="staff"
            >
              <option :value="null">
                {{ $t("note.placeholder.selectOne") }}
              </option>
              <template v-if="users && Array.isArray(users) && users.length">
                <option
                  v-for="(item, key) in users"
                  :key="key"
                  :value="item.id"
                >
                  {{ item.full_name }}
                </option>
              </template>
            </select>
            <span
              v-if="checkValidations(validations, 'cashier_id')"
              class="message-validate"
            >
              {{ validations.cashier_id[0] }}
            </span>
          </div>
          <div class="form-group">
            <label
              class="required"
              for="payment_option"
            >{{
              $t("payment.label.paymentOption")
            }}</label>
            <select
              id="payment_option"
              v-model="payment_option_id"
              :class="{
                'input-danger': checkValidations(
                  validations,
                  'payment_option_id'
                ),
              }"
              class="form-control"
              data-live-search="true"
              title="Please select a lunch ..."
            >
              <option
                :value="null"
                disabled
              >
                {{ $t("note.placeholder.selectOne") }}
              </option>
              <template v-if="payment_options && Array.isArray(payment_options) && payment_options.length">
                <option
                  v-for="(item, key) in payment_options"
                  :key="key"
                  :value="item.id"
                >
                  {{ item["name_" + $i18n.locale] }}
                </option>
              </template>
            </select>
            <span
              v-if="checkValidations(validations, 'payment_option_id')"
              class="message-validate"
            >
              {{ validations.payment_option_id[0] }}
            </span>
          </div>
        </template>
        <textarea
          id="contact_note"
          v-model="note"
          :placeholder="$t('note.placeholder.noteHere')"
          class="form-control"
          cols="30"
          name="note_called"
          rows="10"
        />
      </div>
    </div>
    <div class="col-sm-12 text-right">
      <template v-if="is_edit">
        <button class="btn btn-primary">
          <i class="fas fa-edit" /> {{ $t("global.btn.update") }}
        </button>
      </template>
      <template v-else>
        <button
          class="btn btn-success"
          @click="storePayment"
        >
          <i class="fas fa-check-circle" /> {{ $t("global.btn.submit") }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentForm',
  props: {
    clientRequestUuid: {
      type: String,
      required: true
    },
    payment: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      payment_options: [],
      users: [],
      additionals: [],
      duration_types: [
        { value: 'day', name_km: 'ថ្ងៃ', name_en: 'Day' },
        { value: 'month', name_km: 'ខែ', name_en: 'Month' },
        { value: 'year', name_km: 'ឆ្នាំ', name_en: 'Year' }
      ],
      is_edit: false,
      validations: null,

      payment_uuid: null,
      cashier_id: null,
      total_price: null,
      currency_symbol: '$',
      payment_option_id: null,
      duration: null,
      duration_type: 'month',
      start_date: null,
      end_date: null,
      active: false,
      is_paid: false,
      note: null,
      selected_devices: []
    }
  },
  computed: {
    ...mapGetters('user', {
      cashier: 'getUser'
    })
  },
  mounted () {
    this.getPaymentOptions()
    this.getUserOptions()
    this.getAdditionalOptions()
    if (this.payment) {
      this.payment_uuid = this.payment.uuid
      this.total_price = this.payment.total_price
      this.currency_symbol = this.payment.currency_symbol
      this.duration = this.payment.duration
      this.duration_type = this.payment.duration_type
      this.start_date = this.payment.start_date
      this.end_date = this.payment.end_date
      this.active = this.payment.active
      this.is_paid = this.payment.is_paid
      this.note = this.payment.note
      this.cashier_id = this.payment.cashier_id
      this.payment_option_id = this.payment.payment_option_id
      if (this.payment.additionals) {
        this.payment.additionals.forEach((item) => {
          this.selected_devices.push(item.id)
        })
      }
    } else if (this.cashier) {
      this.cashier_id = this.cashier.id
    }
  },
  methods: {
    clearData () {
      this.cashier_id = null
      this.total_price = null
      this.currency_symbol = '$'
      this.payment_option_id = null
      this.duration = null
      this.duration_type = 'month'
      this.start_date = null
      this.end_date = null
      this.active = false
      this.is_paid = false
      this.note = null
    },
    getUserOptions () {
      this.$axios
        .post('/api/backend/user/get-options')
        .then((res) => {
          this.users = res.data.data
        })
    },
    getPaymentOptions () {
      this.$axios
        .post('/api/backend/payment-option/get-options')
        .then((res) => {
          this.payment_options = res.data.data
        })
    },
    getAdditionalOptions () {
      this.$axios
        .post('/api/backend/additional/get-options')
        .then((res) => {
          this.additionals = res.data.data
        })
    },
    storePayment () {
      this.validations = null
      const data = {
        client_request_uuid: this.clientRequestUuid,
        total_price: this.total_price,
        currency_symbol: this.currency_symbol,
        duration: this.duration,
        duration_type: this.duration_type,
        start_date: this.start_date,
        end_date: this.end_date,
        active: this.active,
        is_paid: this.is_paid,
        note: this.note,
        selected_devices: this.selected_devices
      }
      if (this.payment) {
        data.uuid = this.payment_uuid
      }

      if (this.is_paid) {
        data.cashier_id = this.cashier_id
        data.payment_option_id = this.payment_option_id
      }
      this.$axios.post('/api/backend/payment/store', data)
        .then(() => {
          this.clearData()
          this.$emit('callBackFunction')
        }).catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
    }
  }
}
</script>

<style scoped>
.custom-input-group {
  width: 100%;
}

.custom-input-group input.form-control {
  width: 80%;
}

.custom-input-group select.form-control {
  width: 20%;
}
</style>
