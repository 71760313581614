<template>
  <div class="row">
    <div class="col-sm-4">
      <CustomerDetail />
    </div>
    <div class="col-sm-8">
      <div class="row">
        <div class="col-sm-12">
          <CustomerNextRenewal />
        </div>
        <div class="col-sm-12">
          <CustomerPreviousSubscription />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerDetail from '@/views/customer/_components/CustomerDetail'
import CustomerNextRenewal from '@/views/customer/_components/CustomerNextRenewal'
import CustomerPreviousSubscription from '@/views/customer/_components/CustomerPreviousSubscription'

export default {
  name: 'Show',
  components: {
    CustomerPreviousSubscription,
    CustomerNextRenewal,
    CustomerDetail
  },
  metaInfo () {
    return {
      title: this.$t('string.showCustomer'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped>

</style>
