<template>
  <div>
    <div class="col-sm-12">
      <div class="form-group">
        <label
          class="required"
          for="install_date"
        >{{
          $t("note.label.installationDate")
        }}</label>
        <date-picker
          id="install_date"
          v-model="progress_at"
          :class="{
            'mx-datepicker-error': checkValidations(validations, 'progress_at'),
          }"
          :placeholder="$t('note.placeholder.selectDate')"
          format="YYYY-MM-DD"
          type="date"
          value-type="format"
        />
        <span
          v-if="checkValidations(validations, 'progress_at')"
          class="message-validate"
        >
          {{ validations.progress_at[0] }}
        </span>
      </div>
    </div>
    <div class="col-md-12">
      <div class="form-group">
        <label
          class="required"
          for="install_install_by"
        >{{
          $t("note.label.installBy")
        }}</label>
        <select
          id="install_install_by"
          v-model="staff_id"
          :class="{ 'input-danger': checkValidations(validations, 'staff_id') }"
          class="form-control"
          name="staff"
        >
          <option :value="null">
            {{ $t("note.placeholder.selectOne") }}
          </option>
          <template v-if="users.length">
            <option
              v-for="(item, key) in users"
              :key="key"
              :value="item.id"
            >
              {{ item.full_name }}
            </option>
          </template>
        </select>
        <span
          v-if="checkValidations(validations, 'staff_id')"
          class="message-validate"
        >
          {{ validations.staff_id[0] }}
        </span>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-group">
        <label for="install_note">{{ $t("note.label.note") }}</label>
        <textarea
          id="install_note"
          v-model="note"
          :placeholder="$t('note.placeholder.noteHere')"
          class="form-control"
          cols="30"
          name="note_called"
          rows="10"
        />
      </div>
    </div>
    <div class="col-sm-12 text-right">
      <template v-if="is_edit">
        <button
          class="btn btn-primary"
          @click="storeProgress('install')"
        >
          <i class="fas fa-edit" /> {{ $t("global.btn.update") }}
        </button>
      </template>
      <template v-else>
        <button
          class="btn btn-success"
          @click="storeProgress('install')"
        >
          <i class="fas fa-check-circle" /> {{ $t("global.btn.submit") }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppointmentInstallation',
  props: {
    clientRequestUuid: {
      type: String,
      required: true
    },
    clientProgress: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      users: [],
      validations: null,
      progress_at: null,
      staff_id: null,
      note: null,
      is_edit: false
    }
  },
  mounted () {
    this.getUserOptions()
    if (this.clientProgress) {
      this.is_edit = true
      if (this.$moment(this.clientProgress.progress_at).isValid()) {
        this.progress_at = this.$moment(this.clientProgress.progress_at).format(
          'YYYY-MM-DD'
        )
      } else {
        this.progress_at = null
      }
      this.staff_id = this.clientProgress.staff_id
      this.note = this.clientProgress.note
    } else {
      this.clearData()
    }
  },
  methods: {
    getUserOptions () {
      this.$axios
        .post('/api/backend/user/get-options')
        .then((res) => {
          this.users = res.data.data
        })
    },
    clearData () {
      this.is_edit = false
      this.validations = null
      this.progress_at = null
      this.note = null
      this.staff_id = null
    },
    storeProgress (request_status = null) {
      this.validations = null
      const data = {
        client_request_uuid: this.clientRequestUuid,
        progress_at: this.progress_at
          ? this.$moment(this.progress_at).format('YYYY-MM-DD')
          : null,
        note: this.note,
        staff_id: this.staff_id,
        is_finished: false,
        request_status: request_status
      }
      if (this.clientProgress) data.uuid = this.clientProgress.uuid
      this.$axios
        .post(
          '/api/backend/client-progress/appointment-installation',
          data
        )
        .then(() => {
          this.clearData()
          this.$emit('callBackFunction')
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
    }
  }
}
</script>

<style scoped></style>
