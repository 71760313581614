<template>
  <RouterView />
</template>

<script>
export default {
  name: 'Plan'
}
</script>

<style scoped></style>
