import '@/libraries/stylesheets'
import '@/libraries/scripts'
import '@/assets/css/tailwind.css'

import Vue from 'vue'
import axios from '@/axios'
import App from '@/App.vue'
import getRouter from '@/router'
import store from '@/store'
import { i18n } from '@/i18n'
import { config } from '@/composables/config'
import '@/plugins'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$base_api = process.env.VUE_APP_API
Vue.prototype.$config = config
Vue.prototype.$token = process.env.VUE_APP_TOKEN
Vue.prototype.$refreshToken = process.env.VUE_APP_REFRESH_TOKEN

new Vue({
  router: getRouter(store),
  store,
  i18n,
  config,
  render: h => h(App)
}).$mount('#app')
