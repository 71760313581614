import Index from '@/views/renewalPlanRequest/Index'
import List from '@/views/renewalPlanRequest/List'
import Show from '@/views/renewalPlanRequest/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseRenewalPlanModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.renewalPlanRequest

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'renewalPlanRequest',
    breadcrumb: [
      {
        name_en: 'Renewal Plan Request',
        name_km: 'បន្តគម្រោង'
      },
      link
    ]
  }
}

export const renewalPlanRequestRouters = {
  path: 'renewal-plan-request',
  component: Index,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'renewalPlanRequest'
  },
  children: [
    {
      path: `${baseRenewalPlanModuleUrl}`,
      name: 'list-renewal-plan-request',
      component: List,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:renewal_plan_request_uuid',
      name: 'show-renewal-plan-request',
      component: Show,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
