import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDR1bag41t-hoXfWrtcSFK7K5DC5S73l9U',
    libraries: 'places'
  },
  installComponents: true
})
