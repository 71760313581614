import Secondary from '@/layouts/Secondary'
import Login from '@/views/Login'

export const secondaryRouter = {
  path: '',
  name: 'secondary',
  component: Secondary,
  redirect: '/',
  meta: {
    noAuth: true
  },
  children: [
    {
      path: '',
      name: 'login',
      component: Login,
      meta: {
        noAuth: true
      }
    }
  ]
}
