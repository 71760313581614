<template>
  <service-form />
</template>

<script>
import ServiceForm from './Form.vue'

export default {
  name: 'Create',
  components: { ServiceForm },
  metaInfo () {
    return {
      title: this.$t('page.createNewService'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped></style>
