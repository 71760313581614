<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.customerDetail') }}
      </h3>
    </div>
    <div class="box-body">
      <table class="table table-striped">
        <tbody>
          <template v-if="customer">
            <tr>
              <td>{{ $t('string.name') }}</td>
              <td>{{ customer.name }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.email') }}</td>
              <td>{{ customer.email || $t('string.na') }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.phone') }}</td>
              <td>{{ customer.phone || $t('string.na') }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.service') }}</td>
              <td>{{ customer.service ? customer.service.name : $t('string.na') }}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CustomerDetail',
  data () {
    return {
      customer: null
    }
  },
  methods: {
    ...mapActions('customer', {
      setCustomer: 'setCustomer'
    }),
    getCustomer () {
      this.$isLoading(true)
      this.$axios.post('/api/backend/customer/show', {
        customer_uuid: this.$route.params.customer_uuid
      }).then(({ data }) => {
        if (data.data) {
          this.customer = data.data
          this.setCustomer(this.customer)
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getCustomer()
  }
}
</script>

<style scoped>

</style>
