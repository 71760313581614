<template>
  <div>
    <div class="col-sm-12">
      <div class="form-group">
        <label for="contact_date">{{ $t("note.label.contactedAt") }}</label>
        <date-picker
          id="contact_date"
          v-model="progress_at"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('note.placeholder.selectDate')"
          format="YYYY-MM-DD"
          type="date"
          value-type="format"
        />
      </div>
    </div>
    <div class="col-sm-12">
      <div class="form-group">
        <label for="contact_note">{{ $t("note.label.note") }}</label>
        <textarea
          id="contact_note"
          v-model="note"
          :placeholder="$t('note.placeholder.noteHere')"
          class="form-control"
          cols="30"
          name="note_called"
          rows="10"
        />
      </div>
    </div>
    <div class="col-sm-12 text-right">
      <template v-if="is_edit">
        <button
          class="btn btn-primary"
          @click="storeProgress(clientProgress.status, true)"
        >
          <i class="fas fa-edit" /> {{ $t("global.btn.update") }}
        </button>
      </template>
      <template v-else>
        <button
          class="btn btn-info"
          @click="storeProgress('test_system', true)"
        >
          <i class="fas fa-question-circle" /> {{ $t("btn.testTheSystem") }}
        </button>
        <button
          class="btn btn-success"
          @click="storeProgress('approved', true)"
        >
          <i class="fas fa-check-circle" /> {{ $t("btn.approve") }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressContact',
  props: {
    clientRequestUuid: {
      type: String,
      required: true
    },
    clientProgress: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      validations: null,
      progress_at: null,
      note: null,
      is_edit: false
    }
  },
  mounted () {
    if (this.clientProgress) {
      this.is_edit = true
      if (this.$moment(this.clientProgress.progress_at).isValid()) {
        this.progress_at = this.$moment(this.clientProgress.progress_at).format(
          'YYYY-MM-DD'
        )
      } else {
        this.progress_at = null
      }
      this.note = this.clientProgress.note
    } else {
      this.clearData()
    }
  },
  methods: {
    clearData () {
      this.is_edit = false
      this.validations = null
      this.progress_at = null
      this.note = null
    },
    storeProgress (request_status = null, is_finished = false) {
      const data = {
        client_request_uuid: this.clientRequestUuid,
        progress_at: this.$moment(this.progress_at).isValid()
          ? this.$moment(this.progress_at).format('YYYY-MM-DD')
          : null,
        note: this.note,
        is_finished: is_finished,
        request_status: request_status
      }
      if (this.clientProgress) data.uuid = this.clientProgress.uuid
      this.$axios
        .post('/api/backend/client-progress/contact-client', data)
        .then(() => {
          this.clearData()
          this.$emit('callBackFunction')
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
    }
  }
}
</script>

<style scoped></style>
