<template>
  <footer class="main-footer">
    <div class="pull-right hidden-xs">
      {{ $t('string.developedBy') }} <a
        href="https://asorasoft.com"
        target="_blank"
      >Asorasoft.com</a>
    </div>
    <strong>
      {{ $t('string.copyright') }} &copy; {{ $t("footer.2019") }} {{ $t("footer.allRightsReserved") }} <a href="#">{{ appName }}</a>
    </strong>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      appName: process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped></style>
