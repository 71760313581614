<template>
  <header class="main-header">
    <router-link
      :to="{ name: 'dashboard' }"
      class="logo"
    >
      <span class="logo-mini">{{ app_name_mini }}</span>
      <span class="logo-lg">{{ app_name }}</span>
    </router-link>

    <nav
      class="navbar navbar-static-top"
      role="navigation"
    >
      <a
        href="#"
        class="sidebar-toggle"
        data-toggle="push-menu"
        role="button"
      >
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown tasks-menu">
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              @click="onChangeLocale"
              class="dropdown-toggle"
            >
              <img
                :src="srcFlagImg"
                style="height: 16px"
              >
            </a>
          </li>
          <HeaderUser />
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import HeaderUser from '@/components/HeaderUser'

export default {
  name: 'Header',
  components: { HeaderUser },
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      app_name_mini: process.env.VUE_APP_NAME_MINI
    }
  },
  computed: {
    srcFlagImg () {
      if (this.$i18n.locale === 'km') {
        return '/img/flags/en.png'
      }
      return '/img/flags/km.png'
    }
  },
  methods: {
    onChangeLocale () {
      if (this.$i18n.locale === 'km') {
        this.$i18n.locale = 'en'
        localStorage.setItem('locale', 'en')
      } else {
        this.$i18n.locale = 'km'
        localStorage.setItem('locale', 'km')
      }
    }
  }
}
</script>

<style scoped></style>
