import PaymentLayout from '@/views/payment/Index'
import PaymentIndex from '@/views/payment/List'
import PaymentShow from '@/views/payment/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { basePaymentModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.payment

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'payment',
    breadcrumb: [
      {
        name_en: 'Payment',
        name_km: 'ការទូទាត់ប្រាក់'
      },
      link
    ]
  }
}

export const paymentRouters = {
  path: 'payment',
  component: PaymentLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'payment'
  },
  children: [
    {
      path: `${basePaymentModuleUrl}`,
      name: 'payment-index',
      component: PaymentIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:uuid',
      name: 'payment-show',
      component: PaymentShow,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
