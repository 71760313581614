import Index from '@/views/changePlanRequest/Index'
import List from '@/views/changePlanRequest/List'
import Show from '@/views/changePlanRequest/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseChangePlanModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.changePlanRequest

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'changePlanRequest',
    breadcrumb: [
      {
        name_en: 'Change Plan Request',
        name_km: 'ប្តូរគម្រោង'
      },
      link
    ]
  }
}

export const changePlanRequestRouters = {
  path: 'change-plan-request',
  component: Index,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'change-plan-request'
  },
  children: [
    {
      path: `${baseChangePlanModuleUrl}`,
      name: 'list-change-plan-request',
      component: List,
      meta: getMeta(permissions.list, {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'show/:change_plan_request_uuid',
      name: 'show-change-plan-request',
      component: Show,
      meta: getMeta(permissions.show, {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
