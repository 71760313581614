import Vue from 'vue'
import utils from '@/plugins/utils'

import '@/plugins/x-chart'
import '@/plugins/vuejs-loading-screen'
import '@/plugins/vue-toastr'
import '@/plugins/vue-sweetalert2'
import '@/plugins/vue-multiselect'
import '@/plugins/vue2-google-maps'
import '@/plugins/vue2-datepicker'
import '@/plugins/vuejs-paginate'
import '@/plugins/vue2-editor'
import '@/plugins/v-clipboard'
import '@/plugins/vue-meta'
import '@/plugins/vue-moment'
import '@/plugins/permission'

Vue.use(utils)
