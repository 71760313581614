<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.previousSubscription') }}
      </h3>
    </div>
    <div class="box-body">
      <table
        id="invoices-table"
        class="table table-bordered table-hover"
      >
        <thead>
          <tr>
            <th>{{ $t("table.plan") }}</th>
            <th>{{ $t("table.price") }}</th>
            <th>{{ $t("table.startAt") }}</th>
            <th>{{ $t("table.endAt") }}</th>
            <th>{{ $t("table.created_at") }}</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerPreviousSubscription',
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.getFeature()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $('#invoices-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/customer/invoices',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
            d.customer_uuid = this.$route.params.customer_uuid
          }
        },
        columns: [
          {
            data: 'plan.title',
            name: 'plan.title',
            title: this.$t('table.plan')
          },
          {
            data: 'price',
            name: 'price',
            title: this.$t('table.price'),
            render: (data, type, row) => {
              if (row.plan) {
                if (row.plan.currency_symbol) {
                  return row.plan.currency_symbol + parseFloat((data || 0).toString()).toFixed(2)
                }
                return '$' + parseFloat('0').toFixed(2)
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'start_at',
            name: 'start_at',
            title: this.$t('table.startAt')
          },
          {
            data: 'end_at',
            name: 'end_at',
            title: this.$t('table.endAt')
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('table.created_date')
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[4, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show')

      $(document).on('click', '.btn-show', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')

        self.$router.push({
          name: 'show-customer',
          params: {
            customer_uuid: uuid
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
