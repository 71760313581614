import AdditionalDeviceLayout from '@/views/settings/additionalDevice/Index'
import AdditionalDeviceIndex from '@/views/settings/additionalDevice/List'
import AdditionalDeviceCreate from '@/views/settings/additionalDevice/Create'
import AdditionalDeviceEdit from '@/views/settings/additionalDevice/Edit'
import AdditionalDeviceShow from '@/views/settings/additionalDevice/Show'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.settings.additionalDevice

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'additionalDevice',
    breadcrumb: [
      {
        name_en: 'Settings',
        name_km: 'ការកំណត់'
      },
      {
        name_en: 'Additional Device',
        name_km: 'ឧបករណ៌បន្ថែម'
      },
      link
    ]
  }
}

export const additionalDeviceRouters = {
  path: 'additional-device',
  component: AdditionalDeviceLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'additionalDevice'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/additional-device`,
      name: 'list-additional-device',
      component: AdditionalDeviceIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-additional-device',
      component: AdditionalDeviceCreate,
      meta: getMeta([permissions.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:uuid',
      name: 'edit-additional-device',
      component: AdditionalDeviceEdit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:uuid',
      name: 'additional-device-show',
      component: AdditionalDeviceShow,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
