<template>
  <div class="row">
    <div class="col-md-2">
      <div class="panel panel-default">
        <div class="panel-heading">
          <i class="fa fa-fw fa-flag" /> {{ $t("logs_viewer.string.levels") }}
        </div>
        <template v-if="menu">
          <ul class="list-group">
            <template v-for="(item, key) in menu">
              <template v-if="item['count'] === 0">
                <a
                  href=""
                  :key="key"
                  class="list-group-item disabled"
                >
                  <span class="badge">{{ item["count"] }}</span>
                  <span v-html="item['icon']" />{{ item["name"] }}
                </a>
              </template>
              <template v-else>
                <a
                  href=""
                  :key="key"
                  :class="`list-group-item level-${key}`"
                  @click="getLogsByLevel($event, key)"
                >
                  <span :class="`badge level-${key}`">{{ item["count"] }}</span>
                  <span :class="`level level-${key}`">
                    <span v-html="item['icon']" /> {{ item["name"] }}
                  </span>
                </a>
              </template>
            </template>
          </ul>
        </template>
      </div>
    </div>
    <div class="col-lg-10">
      <div class="row">
        <div class="col-12 mb-4">
          <div class="box box-primary">
            <div class="box-header">
              <h3 class="box-title">
                {{ $t("logs_viewer.string.log_info") }}
              </h3>
              <div class="box-tools">
                <div class="group-btn">
                  <button
                    class="btn btn-sm btn-success margin-right-5"
                    @click="downloadLog($route.params.date)"
                  >
                    <i class="fa fa-download" />
                    {{ $t("logs_viewer.string.download") }}
                  </button>
                  <button
                    class="btn btn-sm btn-danger margin-right-5"
                    @click="deleteLog($route.params.date)"
                  >
                    <i class="fas fa-trash-alt" />
                    {{ $t("logs_viewer.string.delete") }}
                  </button>
                  <ButtonBack />
                </div>
              </div>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table
                  v-if="log"
                  class="table table-condensed mb-0"
                >
                  <tbody>
                    <tr>
                      <td>{{ $t("logs_viewer.string.file_path") }} :</td>
                      <td colspan="7">
                        {{ log.path }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("logs_viewer.string.log_entries") }} :</td>
                      <td>
                        <span class="badge badge-primary">{{
                          log.total_entries
                        }}</span>
                      </td>
                      <td>{{ $t("logs_viewer.string.size") }} :</td>
                      <td>
                        <span class="badge badge-primary">{{ log.size }}</span>
                      </td>
                      <td>{{ $t("logs_viewer.string.created_at") }} :</td>
                      <td>
                        <span class="badge badge-primary">{{
                          log.created_at
                        }}</span>
                      </td>
                      <td>{{ $t("logs_viewer.string.updated_at") }} :</td>
                      <td>
                        <span class="badge badge-primary">{{
                          log.updated_at
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer">
              <div class="form-group">
                <div class="input-group">
                  <input
                    id="query"
                    v-model="filter_text"
                    :placeholder="$t('logs_viewer.string.type_here_to_search')"
                    class="form-control"
                    name="query"
                    @keyup="onSearchLogs"
                  >
                  <span class="input-group-btn">
                    <button
                      v-if="filter_text"
                      class="btn btn-secondary"
                      @click="clearSearchResult"
                    >
                      ({{ filter_results }}
                      {{ $t("logs_viewer.string.results") }})
                      <i class="fa fa-fw fa-times" />
                    </button>
                    <button
                      id="search-btn"
                      class="btn btn-primary"
                    >
                      <span class="fa fa-fw fa-search" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="box box-primary">
            <div class="box-body">
              <div class="table-responsive mb-3">
                <table
                  id="entries"
                  class="table mb-0"
                >
                  <thead>
                    <tr>
                      <th style="width: 120px">
                        {{ $t("logs_viewer.string.env") }}
                      </th>
                      <th style="width: 120px">
                        {{ $t("logs_viewer.string.level") }}
                      </th>
                      <th style="width: 65px">
                        {{ $t("logs_viewer.string.time") }}
                      </th>
                      <th>{{ $t("logs_viewer.string.header") }}</th>
                      <th
                        class="text-right"
                        style="width: 120px"
                      >
                        {{ $t("logs_viewer.string.actions") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="entries && Object.keys(entries).length > 0">
                      <template v-for="(entry, key) in entries">
                        <tr :key="key">
                          <td>
                            <span class="badge badge-env">{{ env }}</span>
                          </td>
                          <td>
                            <span :class="`badge level-${entry.level}`">
                              {{ entry.level }}
                            </span>
                          </td>
                          <td>
                            <span class="badge badge-secondary">
                              {{ entry.datetime }}
                            </span>
                          </td>
                          <td>{{ entry.header }}</td>
                          <td class="text-right">
                            <template
                              v-if="entry.stack && entry.stack.length > 10"
                            >
                              <a
                                :aria-controls="`log-stack-${key}`"
                                :href="`#log-stack-${key}`"
                                aria-expanded="false"
                                class="btn btn-sm btn-light"
                                data-toggle="collapse"
                                role="button"
                              >
                                <i class="fa fa-toggle-on" />
                                {{ $t("logs_viewer.string.stack") }}
                              </a>
                            </template>
                          </td>
                        </tr>
                        <template v-if="entry.stack">
                          <tr :key="`stack-${key}`">
                            <td
                              class="stack py-0"
                              colspan="5"
                            >
                              <div
                                :id="`log-stack-${key}`"
                                class="stack-content collapse"
                              >
                                <pre class="text-red">{{ entry.stack }}</pre>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="5"
                        >
                          <span class="badge badge-secondary">
                            {{
                              $t("logs_viewer.string.the_list_of_logs_is_empty")
                            }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div v-if="totalPage > 1">
                <paginate
                  :click-handler="getLogs"
                  :container-class="'pagination m-0'"
                  :next-text="$t('logs_viewer.pagination.next')"
                  :page-class="'page-item'"
                  :page-count="totalPage"
                  :prev-text="$t('logs_viewer.pagination.prev')"
                  next-link-class="page-link"
                  page-link-class="page-link"
                  prev-link-class="page-link"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from '@/components/BackButton'
import { debounce } from 'debounce'

export default {
  name: 'Show',
  metaInfo () {
    return {
      title: this.$t('logs_viewer.menu'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { ButtonBack },
  data () {
    return {
      menu: [],
      log: null,
      level: 'all',
      entries: [],
      totalPage: 0,
      page: 1,
      env: null,
      filter_text: null,
      filter_results: 0
    }
  },
  methods: {
    getLogs (page = 1) {
      this.page = page
      this.validations = {}
      this.$isLoading(true)
      this.$axios
        .post('/api/backend/logs-viewer/show', {
          date: this.$route.params.date,
          level: this.level,
          page: this.page,
          filter_text: this.filter_text
        })
        .then(({ data }) => {
          if (data.data) {
            const result = data.data
            this.menu = result.menu
            this.log = result.log

            this.env = result.env

            const entries = result.entries
            this.entries = entries.data
            this.totalPage = entries.total_pages

            if (this.filter_text) {
              this.filter_results = entries.total_items
            }
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    deleteLog (date) {
      this.onConfirm().then((accept) => {
        if (accept) {
          this.validations = {}
          this.$isLoading(true)
          this.$axios.post('/api/backend/logs-viewer/delete', {
            date
          }).then(() => {
            this.$router.push({
              name: 'list-logs-viewer'
            })
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    downloadLog (date) {
      this.$axios({
        method: 'POST',
        url: '/api/backend/logs-viewer/download',
        params: { date },
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `laravel-${date}.log`)
        document.body.appendChild(link)
        link.click()
      })
    },
    getLogsByLevel (e, level) {
      e.preventDefault()
      this.level = level
      this.getLogs()
    },
    onSearchLogs: debounce(function () {
      this.getLogs()
    }, 300),
    clearSearchResult () {
      this.filter_text = null
      this.getLogs()
    }
  },
  mounted () {
    this.getLogs()
  }
}
</script>

<style scoped>
.stack-content {
  padding: 8px;
  color: #ae0e0e;
  font-family: consolas, Menlo, Courier, monospace;
  font-size: 12px;
  font-weight: 400;
  white-space: pre-line;
}

.info-box.level {
  display: block;
  padding: 0;
  margin-bottom: 15px;
  min-height: 70px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.info-box.level .info-box-text,
.info-box.level .info-box-number,
.info-box.level .info-box-icon > i {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.info-box.level .info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box.level .info-box-content {
  padding: 5px 10px;
  margin-left: 70px;
}

.info-box.level .info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}

.info-box.level .info-box-icon {
  border-radius: 2px 0 0 2px;
  display: block;
  float: left;
  height: 70px;
  width: 70px;
  text-align: center;
  font-size: 40px;
  line-height: 70px;
  background: rgba(0, 0, 0, 0.2);
}

.info-box.level .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}

.info-box.level .progress .progress-bar {
  background: #fff;
}

.info-box.level-empty {
  opacity: 0.6;
  filter: grayscale(1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transition-property: -webkit-filter, opacity;
  -moz-transition-property: -moz-filter, opacity;
  -o-transition-property: filter, opacity;
  transition-property: -webkit-filter, -moz-filter, -o-filter, filter, opacity;
}

.info-box.level-empty:hover {
  opacity: 1;
  filter: grayscale(0);
}

.level {
  padding: 2px 6px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-size: 0.9em;
  font-weight: 600;
}

.badge.level-all,
.badge.level-emergency,
.badge.level-alert,
.badge.level-critical,
.badge.level-error,
.badge.level-warning,
.badge.level-notice,
.badge.level-info,
.badge.level-debug,
.level,
.level i,
.info-box.level-all,
.info-box.level-emergency,
.info-box.level-alert,
.info-box.level-critical,
.info-box.level-error,
.info-box.level-warning,
.info-box.level-notice,
.info-box.level-info,
.info-box.level-debug {
  color: #fff;
}

.label-env {
  font-size: 0.85em;
}

.badge.level-all,
.level.level-all,
.info-box.level-all {
  background-color: #8a8a8a;
}

.badge.level-emergency,
.level.level-emergency,
.info-box.level-emergency {
  background-color: #b71c1c;
}

.badge.level-alert,
.level.level-alert,
.info-box.level-alert {
  background-color: #d32f2f;
}

.badge.level-critical,
.level.level-critical,
.info-box.level-critical {
  background-color: #f44336;
}

.badge.level-error,
.level.level-error,
.info-box.level-error {
  background-color: #ff5722;
}

.badge.level-warning,
.level.level-warning,
.info-box.level-warning {
  background-color: #ff9100;
}

.badge.level-notice,
.level.level-notice,
.info-box.level-notice {
  background-color: #4caf50;
}

.badge.level-info,
.level.level-info,
.info-box.level-info {
  background-color: #1976d2;
}

.badge.level-debug,
.level.level-debug,
.info-box.level-debug {
  background-color: #90caf9;
}

.badge.level-empty,
.level.level-empty {
  background-color: #d1d1d1;
}

.badge.label-env,
.label.label-env {
  background-color: #6a1b9a;
}

.list-group-item.disabled {
  color: #777 !important;
  cursor: not-allowed !important;
  background-color: #eee !important;
}
</style>
