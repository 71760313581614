export const naming = {
  manage: {
    en: 'Manage $$',
    km: 'គ្រប់គ្រង$$'
  },
  list: {
    en: 'List $$',
    km: 'មើលបញ្ជី$$'
  },
  show: {
    en: 'View $$ Detail',
    km: 'បង្ហាញព័ត៌មានលម្អិត$$'
  },
  store: {
    en: 'Store $$',
    km: 'រក្សាទិន្នន័យ$$'
  },
  edit: {
    en: 'Edit $$',
    km: 'កែសម្រួលទិន្នន័យ$$'
  },
  delete: {
    en: 'Delete $$',
    km: 'លុបទិន្នន័យ$$'
  },
  changePassword: {
    en: 'Change $$\'s Password',
    km: 'កែពាក្យសម្ងាត់របស់$$'
  },
  approve: {
    en: 'Approve $$',
    km: 'អនុម័ត$$'
  },
  reject: {
    en: 'Reject $$',
    km: 'បដិសេធ$$'
  },
  refreshSecret: {
    en: 'Refresh Secret $$',
    km: 'កំណត់ឡើងវិញ Secret $$'
  },
  viewCountsWidget: {
    en: 'View Counts Widget $$',
    km: 'មើលធាតុក្រាហ្វិក$$'
  },
  viewExpiredWidget: {
    en: 'View Expired Widget $$',
    km: 'មើលធាតុក្រាហ្វិកផុតកំណត់$$'
  },
  trashed: {
    en: 'Trashed $$',
    km: 'លុប$$'
  },
  toggle: {
    en: 'Toggle $$',
    km: 'បិទ/បើក$$'
  },
  restore: {
    en: 'Restore $$',
    km: 'យកមកវិញ$$'
  },
  listTrashed: {
    en: 'List Trashed $$',
    km: 'បញ្ជីលុបនៃ$$'
  },
  viewDueWidget: {
    en: 'View Due Widget $$',
    km: 'មើលធាតុក្រាហ្វិកផុតកំណត់$$'
  }
}
