<template>
  <div class="plan">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("plan.boxTitle.listOfPlan") }}
        </h3>
        <div class="box-tools">
          <div class="button-space-x">
            <button
              v-if="can([$Permissions.backend.settings.plan.delete])"
              class="btn btn-default btn-sm"
              @click="$router.push({name: 'trash-plan'})"
            >
              <i class="fa fa-trash" /> {{ $t('global.btn.trash') }}
            </button>
            <router-link
              v-if="can([$Permissions.backend.settings.plan.store])"
              :to="{ name: 'create-plan' }"
              class="btn btn-success btn-sm"
            >
              <i class="fa fa-plus-circle" /> {{ $t("plan.btn.createPlan") }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <table
              id="plan-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("plan.table.name") }}</th>
                  <th>{{ $t("plan.table.price") }}</th>
                  <th>{{ $t("plan.label.service") }}</th>
                  <th>{{ $t("plan.table.createdDate") }}</th>
                  <th>{{ $t("plan.table.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo () {
    return {
      title: this.$t('page.planList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null,
      Plans: []
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.getPlans()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    getPlans () {
      const self = this
      return $('#plan-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        columnDefs: [{ width: '10%', targets: [0, 1, 2, 3, 4] }],
        fixedColumns: true,
        ajax: {
          url: self.$base_api + '/api/backend/plans/datatable',
          type: 'POST'
        },
        columns: [
          { data: 'title', name: 'title', title: this.$t('plan.table.name') },
          { data: 'price', name: 'price', title: this.$t('plan.table.price') },
          {
            data: 'service_name',
            name: 'service_name',
            title: this.$t('plan.label.service')
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('plan.table.createdDate'),
            searchable: false
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('plan.table.actions'),
            searchable: false,
            orderable: false,
            width: '10%'
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[3, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show-plan')
      $(document).off('click', '.btn-edit-plan')

      $(document).on('click', '.btn-show-plan', function (e) {
        e.preventDefault()
        self.$router.push({
          name: 'show-plan',
          params: {
            uuid: $(this).attr('data-uuid')
          }
        })
      })

      $(document).on('click', '.btn-edit-plan', function (e) {
        e.preventDefault()
        self.$router.push({
          name: 'edit-plan',
          params: {
            uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.getPlans()
    this.buttonAction()
  }
}
</script>

<style scoped></style>
