import ServiceLayout from '@/views/service/Index'
import ServiceIndex from '@/views/service/List'
import ServiceCreate from '@/views/service/Create'
import ServiceEdit from '@/views/service/Edit'
import ServiceShow from '@/views/service/Show'
import ServiceTrash from '@/views/service/Trash'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseServiceModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.service

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'service',
    breadcrumb: [
      {
        name_en: 'Service',
        name_km: 'សេវាកម្ម'
      },
      link
    ]
  }
}

export const serviceRouters = {
  path: 'service',
  component: ServiceLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'service'
  },
  children: [
    {
      path: `${baseServiceModuleUrl}`,
      name: 'list-service',
      component: ServiceIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-service',
      component: ServiceCreate,
      meta: getMeta([permissions.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:uuid',
      name: 'edit-service',
      component: ServiceEdit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:uuid',
      name: 'show-service',
      component: ServiceShow,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
    {
      path: 'trash',
      name: 'trash-service',
      component: ServiceTrash,
      meta: getMeta([permissions.listTrashed], {
        name_en: 'Trash',
        name_km: 'លុប'
      })
    }
  ]
}
