<template>
  <div class="client-request">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("shopType.boxTitle.listOfShopType") }}
        </h3>
        <div class="box-tools">
          <router-link
            v-if="can([$Permissions.backend.settings.plan.store])"
            :to="{ name: 'create-shop-type' }"
            class="btn btn-success btn-sm"
          >
            <i class="fa fa-plus-circle" />
            {{ $t("shopType.boxTitle.createShopType") }}
          </router-link>
        </div>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <table
              id="shop-type-table"
              class="table table-bordered table-hover"
            >
              <thead>
                <tr>
                  <th>{{ $t("table.name_km") }}</th>
                  <th>{{ $t("table.name_en") }}</th>
                  <th>{{ $t("table.active") }}</th>
                  <th>{{ $t("table.created_date") }}</th>
                  <th>{{ $t("table.actions") }}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  metaInfo () {
    return {
      title: this.$t('page.shopTypeList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.getShopType()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  mounted () {
    this.oTable = this.getShopType()
    this.buttonAction()
  },
  methods: {
    getShopType () {
      const self = this
      return $('#shop-type-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        scrollX: true,
        scrollCollapse: true,
        autoWidth: false,
        ajax: {
          url: self.$base_api + '/api/backend/shop-type/datatable',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
          }
        },
        columns: [
          { data: 'name_km', name: 'name_km', title: this.$t('table.name_km') },
          { data: 'name_en', name: 'name_en', title: this.$t('table.name_en') },
          { data: 'active', name: 'active', title: this.$t('table.active') },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('table.created_date')
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('client_request.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [
          [3, 'desc']
        ]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-toggle-shop-type')
      $(document).off('click', '.btn-edit-shop-type')
      $(document).off('click', '.btn-delete-shop-type')

      $(document).on('click', '.btn-toggle-shop-type', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')
        self.toggleActive(uuid)
      })

      $(document).on('click', '.btn-edit-shop-type', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')
        self.$router.push({
          name: 'edit-shop-type',
          params: {
            uuid: uuid
          }
        })
      })

      $(document).on('click', '.btn-delete-shop-type', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')
        self.delete(uuid)
      })
    },
    delete (uuid) {
      const self = this
      self
        .$swal({
          type: 'warning',
          title: self.$t('swal.delete.title.deleteShopType'),
          text: self.$t('swal.delete.text.deleteAdditionalDevice'),
          showCancelButton: true,
          confirmButtonText: this.$t('global.btn.deleteIt'),
          cancelButtonText: this.$t('global.btn.noDelete')
        })
        .then((result) => {
          if (result.value) {
            this.$axios.post('/api/backend/shop-type/delete', {
              uuid: uuid
            }).then(() => {
              self.$swal({
                title: self.$t('label.feature'),
                text: self.$t('swal.delete.text.deletedShopType'),
                type: 'success'
              })
              this.oTable.draw(true)
            }).catch((error) => {
              this.onResponseError(error)
            })
          }
        })
    },
    toggleActive (uuid) {
      this.$axios.post('/api/backend/shop-type/toggle', {
        uuid: uuid
      }).then(() => {
        this.oTable.draw(true)
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  }
}
</script>

<style scoped></style>
