<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t("nav.changePlanRequest") }}
      </h3>
    </div>
    <div class="box-body">
      <table
        id="change-plan-request-table"
        class="table table-bordered table-hover"
      >
        <thead>
          <tr>
            <th>{{ $t("table.customer") }}</th>
            <th>{{ $t("table.service") }}</th>
            <th>{{ $t("table.fromPlan") }}</th>
            <th>{{ $t("table.toPlan") }}</th>
            <th>{{ $t("table.duration") }}</th>
            <th>{{ $t("table.status") }}</th>
            <th>{{ $t("table.created_at") }}</th>
            <th>{{ $t("table.actions") }}</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo () {
    return {
      title: this.$t('nav.changePlanRequest'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $('#change-plan-request-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/change-plan-request/datatable',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
          }
        },
        columns: [
          {
            data: 'customer.name',
            name: 'customer.name',
            title: this.$t('table.customerName'),
            orderable: false
          },
          {
            data: 'service.name', name: 'service.name', title: this.$t('table.service')
          },
          {
            data: 'from_plan.title', name: 'fromPlan.title', title: this.$t('table.fromPlan')
          },
          {
            data: 'to_plan.title', name: 'toPlan.title', title: this.$t('table.toPlan')
          },
          {
            data: 'duration_value',
            name: 'duration_value',
            title: this.$t('table.duration'),
            render: (data, type, row) => {
              return `${data}/${row.duration_type}`
            }
          },
          {
            data: 'status',
            name: 'status',
            title: this.$t('table.status'),
            render: (data) => {
              if (data === 'Pending') {
                return '<span class="label label-info">Pending</span>'
              } else if (data === 'Rejected') {
                return '<span class="label label-danger">Rejected</span>'
              } else {
                return '<span class="label label-primary">Approved</span>'
              }
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('table.created_date')
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('client_request.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[6, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show')

      $(document).on('click', '.btn-show', function (e) {
        e.preventDefault()
        self.$router.push({
          name: 'show-change-plan-request',
          params: {
            change_plan_request_uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
