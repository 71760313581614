<template>
  <div class="row mt-15">
    <div class="col-md-12">
      <div class="box box-info">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("label.nearExpiredDate") }}
          </h3>
          <div class="box-tools">
            <div class="btn-group">
              <button
                v-for="(item, key) in filterOptions"
                :key="key"
                type="button"
                @click="activeFilterOption = item.value; oTable.draw()"
                :class="{'active' : item.value === activeFilterOption}"
                class="btn btn-default btn-sm"
              >
                {{ item.label }}
              </button>
            </div>
          </div>
        </div>
        <div class="box-body">
          <table
            id="customer-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("table.name") }}</th>
                <th>{{ $t("table.email") }}</th>
                <th>{{ $t("table.phone") }}</th>
                <th>{{ $t("table.service") }}</th>
                <th>{{ $t("table.currentPlan") }}</th>
                <th>{{ $t("table.nextRenewalDate") }}</th>
                <th>{{ $t("table.actions") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpiredList',
  data () {
    return {
      oTable: null,
      activeFilterOption: 'today'
    }
  },
  computed: {
    filterOptions () {
      return [
        { label: this.$t('string.today'), value: 'today' },
        { label: this.$t('string.tomorrow'), value: 'tomorrow' },
        { label: this.$t('string.this_week'), value: 'this_week' },
        { label: this.$t('string.this_month'), value: 'this_month' }
      ]
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $('#customer-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/dashboard/get-expired-datatable',
          type: 'POST',
          data: (d) => {
            d.lang = this.$i18n.locale
            d.filter_option = this.activeFilterOption
          }
        },
        columns: [
          {
            data: 'name',
            name: 'name',
            title: this.$t('table.name')
          },
          {
            data: 'email',
            name: 'email',
            title: this.$t('table.email'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'phone',
            name: 'phone',
            title: this.$t('table.phone'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'service.name',
            name: 'service.name',
            title: this.$t('table.service'),
            orderable: false
          },
          {
            data: 'current_plan.title',
            name: 'currentPlan.title',
            title: this.$t('table.currentPlan'),
            orderable: false
          },
          {
            data: 'next_renewal_date',
            name: 'next_renewal_date',
            title: this.$t('table.nextRenewalDate')
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('client_request.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[5, 'desc']]
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show')

      $(document).on('click', '.btn-show', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')

        self.$router.push({
          name: 'show-customer',
          params: {
            customer_uuid: uuid
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
