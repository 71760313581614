export const dependencies = {
  'show-user': [
    'list-user'
  ],
  'edit-user': [
    'show-user'
  ],
  'change-user-password': [
    'show-user'
  ],
  'delete-user': [
    'show-user'
  ],
  'edit-role': [
    'show-role'
  ],
  'approve-change-plan-request': [
    'list-change-plan-request'
  ],
  'reject-change-plan-request': [
    'list-change-plan-request'
  ],
  'approve-renewal-plan-request': [
    'list-renewal-plan-request'
  ],
  'reject-renewal-plan-request': [
    'list-renewal-plan-request'
  ],
  'show-service': [
    'list-service'
  ],
  'edit-service': [
    'show-service'
  ],
  'refresh-secret': [
    'show-service'
  ],
  'show-client-request': [
    'list-client-request'
  ],
  'show-additional-device': [
    'list-additional-device'
  ],
  'toggle-additional-device': [
    'toggle-additional-device'
  ],
  'edit-additional-device': [
    'show-additional-device'
  ],
  'show-plan': [
    'list-plan'
  ],
  'edit-plan': [
    'show-plan'
  ],
  'show-feature': [
    'list-feature'
  ],
  'toggle-feature': [
    'list-feature'
  ],
  'edit-feature': [
    'show-feature'
  ],
  'show-shop-type': [
    'list-shop-type'
  ],
  'edit-shop-type': [
    'show-shop-type'
  ],
  'show-payment': [
    'list-payment'
  ],
  'toggle-payment': [
    'list-payment'
  ],
  'edit-payment': [
    'show-payment'
  ],
  'show-customer': [
    'list-customer'
  ],
  'edit-customer': [
    'show-customer'
  ]
}
