import Index from '@/views/dashboard/Index'
import Dashboard from '@/views/dashboard/Dashboard'
import Earn from '@/views/dashboard/Earn'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'

const { baseDashboardModuleUrl } = useBaseRouteModules()

export const dashboardRouters = {
  path: 'dashboard',
  component: Index,
  redirect: '',
  meta: {
    groups: 'dashboard'
  },
  children: [
    {
      path: `${baseDashboardModuleUrl}`,
      name: 'dashboard',
      component: Dashboard,
      meta: {
        groups: 'dashboard'
      }
    },
    {
      path: 'earn',
      name: 'dashboard-earn',
      component: Earn,
      meta: {
        groups: 'dashboard'
      }
    }
  ]
}
