<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("service.boxTitle.serviceDetail") }}
          </h3>
          <div class="box-tools">
            <router-link
              v-if="can([$Permissions.backend.service.edit])"
              :to="{
                name: 'edit-service',
                params: {
                  uuid: $route.params.uuid
                }
              }"
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-edit" /> {{ $t("service.btn.editService") }}
            </router-link>
            <BackButton />
          </div>
        </div>
        <div class="box-body">
          <table
            v-if="service"
            class="table table-striped table-bordered"
          >
            <tbody>
              <tr>
                <td>{{ $t("label.serviceName") }}</td>
                <td>{{ service.name }}</td>
              </tr>
              <tr>
                <td>{{ $t("label.serviceShortcode") }}</td>
                <td>{{ service.short_cut }}</td>
              </tr>
              <tr>
                <td>{{ $t("label.description") }}</td>
                <td>{{ service.description || $t('string.na') }}</td>
              </tr>
              <tr>
                <td>{{ $t("service.table.createdDate") }}</td>
                <td>{{ $moment(service.created_at).format("LL") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("string.plans") }}
          </h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-sm-12">
              <template v-if="service && service.plans && Array.isArray(service.plans) && service.plans.length > 0">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>{{ $t('table.title') }}</th>
                      <th>{{ $t('table.price') }}</th>
                      <th>{{ $t('table.createdAt') }}</th>
                      <th>{{ $t('table.action') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(plan, key) in service.plans"
                      :key="key"
                    >
                      <td>{{ plan.title }}</td>
                      <td>
                        <template v-if="plan.price">
                          {{ plan.currency_symbol ? plan.currency_symbol : "$" }} {{
                            parseFloat(plan.price || 0).toFixed(2)
                          }}
                        </template>
                        <template v-else>
                          {{ plan.pricing_type['name_' + $i18n.locale] }}
                        </template>
                      </td>
                      <td>{{ $moment(plan.created_at).format("LL") }}</td>
                      <td>
                        <router-link
                          v-if="can([$Permissions.backend.settings.plan.show])"
                          :to="{
                            name: 'show-plan',
                            params: { uuid: plan.uuid },
                          }"
                          class="btn btn-default btn-xs"
                        >
                          <i class="fa fa-search" />
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <div class="col-sm-12 text-center margin-bottom">
                  <img
                    alt="Create new plan"
                    class="img-responsive"
                    src="/img/svg/create_new.svg"
                  >
                </div>
                <div class="col-sm-12 text-center">
                  <h4>{{ $t("label.createYourPlanHere") }}</h4>
                </div>
                <div
                  v-if="service"
                  class="col-sm-12 text-center"
                  style="margin: 15px 0"
                >
                  <router-link
                    v-if="can([$Permissions.backend.settings.plan.store])"
                    :to="{
                      name: 'plan-create',
                      params: { service_uuid: service.uuid },
                    }"
                    class="btn btn-success"
                  >
                    {{ $t("plan.btn.createPlan") }}
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.apiKeys') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([$Permissions.backend.service.refreshSecret])"
              class="btn btn-warning btn-sm"
              @click="renewApiKey"
            >
              <i class="fas fa-sync-alt" /> {{ $t('button.renew') }}
            </button>
          </div>
        </div>
        <div class="box-body">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th>{{ $t('table.name') }}</th>
                <th>{{ $t('table.token') }}</th>
                <th>{{ $t('table.created') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Publishable key</td>
                <td>
                  <template v-if="service">
                    <span v-clipboard="() => service.secret_key">{{ service.secret_key }}</span>
                  </template>
                  <template v-else>
                    {{ $t('string.na') }}
                  </template>
                </td>
                <td>12 Aug, 2021</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'ServiceDetail',
  components: { BackButton },
  data () {
    return {
      service: null
    }
  },
  methods: {
    getServiceByUuid () {
      this.$isLoading(true)
      this.$axios.post('/api/backend/services/show', {
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.service = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    renewApiKey () {
      this.onConfirm({
        title: this.$t('string.renewApiKey'),
        text: this.$t('string.doYouWantToRenewApiKey?'),
        confirmButtonText: this.$t('button.yes'),
        cancelButtonText: this.$t('button.no')
      }).then((accept) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post('/api/backend/services/renew-api-key', {
            service_uuid: this.$route.params.uuid
          }).then(({ data }) => {
            if (data.data) {
              this.getServiceByUuid()
            }
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.getServiceByUuid()
    }
  }
}
</script>

<style scoped>

</style>
