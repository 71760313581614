export const unauthorizedRouters = {
  path: '/unauthorized',
  name: 'unauthorized',
  component: () => import('@/layouts/Primary'),
  meta: {
    noAuth: true
  },
  children: [
    {
      path: '*',
      component: () => import('@/views/errors/401'),
      meta: {
        breadcrumb: [
          {
            name_en: 'Error',
            name_km: 'ទំព័ររកមិនឃើញ'
          },
          {
            name_en: '401',
            name_km: '៤០១'
          }
        ]
      }
    }
  ]
}
