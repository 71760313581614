<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    const self = this

    if (localStorage.getItem(this.$token)) {
      this.getUserInfo()
      $.ajaxSetup({
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(this.$token)
        }
      })
    }

    if ($ && $.fn && $.fn.dataTable && $.fn.dataTable.ext && $.fn.dataTable.ext.errMode) {
      $.fn.dataTable.ext.errMode = 'none'
    }

    $('body').on('error.dt', function (e, settings, techNode, message) {
      self.$toastr('error', message, self.$t('string.error'))
    })
  }
}
</script>

<style>

</style>
