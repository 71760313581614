<template>
  <RouterView />
</template>

<script>
export default {
  name: 'Payment'
}
</script>

<style scoped>

</style>
