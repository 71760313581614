export const state = () => {
  return {
    data: {}
  }
}

export const getters = {
  customer: state => state.data
}

export const mutations = {
  setCustomer (state, customer) {
    state.data = customer
  }
}

export const actions = {
  setCustomer ({ commit }, customer) {
    commit('setCustomer', customer)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
