<template>
  <div class="image-no-data">
    <img
      src="/img/no_data.jpg"
      alt="no-data"
    >
    <p class="font-secondary">
      {{ show_text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ImgNoData',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    show_text: {
      type: String,
      default () {
        return this.$t('label.no_any_record')
      }
    }
  }
}
</script>

<style scoped>
.image-no-data {
  text-align: center;
}

.image-no-data img {
  display: block;
  width: 75%;
  margin: 0 auto;
}

.image-no-data p {
  font-size: 17px;
  margin-bottom: 5rem;
}
</style>
