var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"section",staticClass:"row",attrs:{"id":"show-client-request"}},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"client-request"},[_c('SectionClient',{attrs:{"client":_vm.client_request,"title":_vm.$t('client_request.boxTitle.clientRequestDetail')}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"box box-primary"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.form_title))+" ")]),_c('div',{staticClass:"box-tools"},[(_vm.show_form)?_c('button',{staticClass:"btn btn-default btn-sm",on:{"click":_vm.cancelNote}},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" "+_vm._s(_vm.$t("btn.cancel"))+" ")]):[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){_vm.showHideForm(true, 'contact', _vm.$t('note.label.addContactNote'))}}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("note.label.contactNote"))+" ")]),(
                !_vm.progresses.some(function (item) { return item.progress_type === 'Install'; })
              )?[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){_vm.showHideForm(true, 'install', _vm.$t('note.label.addAppointment'))}}},[_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" "+_vm._s(_vm.$t("note.label.appointment"))+" ")])]:_vm._e()]],2)]),_c('div',{staticClass:"box-body"},[(_vm.show_form)?[_c('div',{staticClass:"row"},[(_vm.type)?[(_vm.type === 'contact')?[_c('ProgressContact',{attrs:{"client-progress":_vm.client_progress,"client-request-uuid":_vm.client_request.uuid},on:{"callBackFunction":_vm.getClientRequest}})]:(_vm.type === 'install')?[_c('AppointmentInstallation',{attrs:{"client-progress":_vm.client_progress,"client-request-uuid":_vm.client_request.uuid},on:{"callBackFunction":_vm.getClientRequest}})]:_vm._e()]:_vm._e()],2)]:[(_vm.progresses.length)?[_vm._l((_vm.progresses),function(item,key){return [(item.progress_type === 'Install')?_c('div',{key:key},[_c('progress-list-item',{attrs:{"item":item,"statuses":_vm.statuses,"progress-item-class":"progress-item-appointment","title":_vm.$t('note.label.appointmentInstall')},on:{"clickButtonDelete":function($event){return _vm.deleteClientProgress(item.uuid)},"clickButtonEdit":function($event){_vm.showClientProgress(
                      item.uuid,
                      'install',
                      _vm.$t('note.label.editAppointment')
                    )},"clickButtonInstalled":function($event){return _vm.markAsInstalled(item.uuid)}}})],1):(item.progress_type === 'Contact')?[(item.status === 'approved')?[_c('ProgressListItem',{key:key,attrs:{"item":item,"statuses":_vm.statuses,"progress-item-class":"progress-item-approve","title":_vm.$t('note.label.contactClientApproved')},on:{"clickButtonDelete":function($event){return _vm.deleteClientProgress(item.uuid)},"clickButtonEdit":function($event){_vm.showClientProgress(
                        item.uuid,
                        'contact',
                        _vm.$t('note.label.editContactNote')
                      )}}})]:[_c('ProgressListItem',{key:key,attrs:{"item":item,"statuses":_vm.statuses,"progress-item-class":"progress-item-testing","title":_vm.$t('note.label.contactClientTest')},on:{"clickButtonDelete":function($event){return _vm.deleteClientProgress(item.uuid)},"clickButtonEdit":function($event){_vm.showClientProgress(
                        item.uuid,
                        'contact',
                        _vm.$t('note.label.editContactNote')
                      )}}})]]:_vm._e()]}),(_vm.page.total_pages > 1)?_c('paginate',{staticClass:"float-right",attrs:{"click-handler":_vm.getProgressByClient,"container-class":'pagination',"margin-pages":1,"next-class":'page-item',"next-link-class":'page-link',"next-text":'Next',"page-class":'page-item',"page-count":_vm.page.total_pages,"page-link-class":'page-link',"page-range":1,"prev-class":'page-item',"prev-link-class":'page-link',"prev-text":'Prev'},model:{value:(_vm.page.current_page),callback:function ($$v) {_vm.$set(_vm.page, "current_page", $$v)},expression:"page.current_page"}}):_vm._e()]:[_c('img-no-data')]]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }