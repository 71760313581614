<template>
  <div
    v-if="renewalPlanRequest"
    class="row"
  >
    <div class="col-sm-3">
      <div class="box box-primary">
        <div class="box-header with-border">
          <div class="box-title">
            {{ $t('string.customerAndService') }}
          </div>
        </div>
        <div class="box-body">
          <table class="table table-striped table-bordered">
            <tbody>
              <template v-if="customer">
                <tr>
                  <td colspan="2">
                    <strong>{{ $t('string.customer') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.name') }}</td>
                  <td>
                    <strong>{{ customer.name }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.email') }}</td>
                  <td>
                    <strong>{{ customer.email || $t('string.na') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.phone') }}</td>
                  <td>
                    <strong>{{ customer.phone || $t('string.na') }}</strong>
                  </td>
                </tr>
              </template>
              <template v-if="service">
                <tr>
                  <td colspan="2">
                    <strong>{{ $t('string.service') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.name') }}</td>
                  <td>
                    <strong>{{ service.name }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.code') }}</td>
                  <td>
                    <strong>{{ service.short_cut || $t('string.na') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.description') }}</td>
                  <td>
                    <strong>{{ service.description || $t('string.na') }}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.website') }}</td>
                  <td>
                    <strong>{{ service.base_url || $t('string.na') }}</strong>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="row">
        <div
          v-if="renewalPlanRequest.status === 'Pending'"
          class="col-sm-12"
        >
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('string.currentPlan') }}
              </h3>
            </div>
            <div
              v-if="currenPlan"
              class="box-body"
            >
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>{{ $t('string.planName') }}</td>
                    <td>{{ currenPlan.title }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('string.nextRenewalDate') }}</td>
                    <td>
                      <template v-if="customer">
                        {{ $moment(customer.next_renewal_date).format('DD/MM/YYYY h:m:s A') }}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('string.nextRenewalPrice') }}</td>
                    <td>
                      <template v-if="customer && customer.next_renewal_price && currenPlan.currency_symbol">
                        {{ currenPlan.currency_symbol }}
                        {{ parseFloat((customer.next_renewal_price).toString()).toFixed(2) }}
                      </template>
                      <template v-else>
                        USD {{ parseFloat((customer.next_renewal_price).toString()).toFixed(2) }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('string.plan') }}
              </h3>
              <div class="box-tools">
                <div class="button-space-x">
                  <template v-if="renewalPlanRequest.status === 'Pending'">
                    <button
                      v-if="can([$Permissions.backend.renewalPlanRequest.approve])"
                      class="btn btn-primary btn-sm"
                      data-target="#approveRequestModal"
                      data-toggle="modal"
                    >
                      <i class="fa fa-check-circle" /> {{ $t('button.approve') }}
                    </button>
                    <button
                      v-if="can([$Permissions.backend.renewalPlanRequest.approve])"
                      class="btn btn-warning btn-sm"
                      data-target="#rejectRequestModal"
                      data-toggle="modal"
                    >
                      <i class="fa fa-times-circle" /> {{ $t('button.reject') }}
                    </button>
                  </template>
                  <BackButton />
                </div>
              </div>
            </div>

            <div class="box-body">
              <table class="table table-striped table-bordered">
                <tbody>
                  <template v-if="plan">
                    <tr>
                      <td>{{ $t('string.plan') }}</td>
                      <td>
                        <strong>{{ plan.title }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.duration') }}</td>
                      <td>
                        <strong>
                          {{ renewalPlanRequest.duration_value }}/{{ renewalPlanRequest.duration_type }}
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.price') }}</td>
                      <td>
                        <strong>{{ plan.currency_symbol }} {{ renewalPlanRequest.price }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.requestStatus') }}</td>
                      <td>
                        <template v-if="renewalPlanRequest.status === 'Pending'">
                          <span class="label label-info">Pending</span>
                        </template>
                        <template v-else-if="renewalPlanRequest.status === 'Rejected'">
                          <span class="label label-danger">Rejected</span>
                        </template>
                        <template v-else>
                          <span class="label label-primary">Approved</span>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="rejectedBy">
                      <td>{{ $t('string.rejectedBy') }}</td>
                      <td>
                        <p>{{ $t('string.user') }}: <strong>{{ rejectedBy.full_name }}</strong></p>
                        <p>{{ $t('string.reason') }}: <strong>{{ (renewalPlanRequest.reason_reject) }}</strong></p>
                        <p>
                          {{ $t('string.date') }}: <strong>{{
                            $moment(renewalPlanRequest.rejected_at).format('LL')
                          }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr v-if="approvedBy">
                      <td>{{ $t('string.approvedBy') }}</td>
                      <td>
                        <p>{{ $t('string.user') }}: <strong>{{ approvedBy.full_name }}</strong></p>
                        <p>{{ $t('string.reason') }}: <strong>{{ (renewalPlanRequest.reason_approve) }}</strong></p>
                        <p>
                          {{ $t('string.date') }}: <strong>{{
                            $moment(renewalPlanRequest.approved_at).format('LL')
                          }}</strong>
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('string.documents') }}
              </h3>
            </div>
            <div class="box-body">
              <div
                v-if="documents"
                class="documents"
              >
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>{{ $t('table.filename') }}</th>
                      <th>{{ $t('table.fileSize') }}</th>
                      <th>{{ $t('table.createdAt') }}</th>
                      <th>{{ $t('table.actions') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(document, key) in documents"
                      :key="key"
                    >
                      <td>{{ document.filename }}</td>
                      <td>
                        <template v-if="document.size">
                          {{ parseFloat((document.size / (1024)).toString()).toFixed(2) }} {{ $t('string.kb') }}
                        </template>
                        <template v-else>
                          {{ $t('string.na') }}
                        </template>
                      </td>
                      <td>
                        {{ $moment(document.created_at).format('LL') }}
                      </td>
                      <td>
                        <a
                          :href="`${$base_api}/${document.src}`"
                          class="btn btn-info btn-xs"
                          target="_blank"
                        >
                          <i class="fa fa-search" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RejectRequestModal @getRenewalPlanRequest="getRenewalPlanRequest" />
    <ApproveRequestModal @getRenewalPlanRequest="getRenewalPlanRequest" />
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'
import RejectRequestModal from '@/views/renewalPlanRequest/includes/RejectRequestModal'
import ApproveRequestModal from '@/views/renewalPlanRequest/includes/ApproveRequestModal'
import { mapActions } from 'vuex'

export default {
  name: 'Show',
  components: { ApproveRequestModal, RejectRequestModal, BackButton },
  metaInfo () {
    return {
      title: this.$t('string.renewalPlanRequestDetail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      renewalPlanRequest: null
    }
  },
  computed: {
    customer () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.customer) {
        return this.renewalPlanRequest.customer
      }
      return null
    },
    service () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.service) {
        return this.renewalPlanRequest.service
      }
      return null
    },
    plan () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.plan) {
        return this.renewalPlanRequest.plan
      }
      return null
    },
    documents () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.documents) {
        return this.renewalPlanRequest.documents
      }
      return null
    },
    approvedBy () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.approved_by) {
        return this.renewalPlanRequest.approved_by
      }
      return null
    },
    rejectedBy () {
      if (this.renewalPlanRequest && this.renewalPlanRequest.rejected_by) {
        return this.renewalPlanRequest.rejected_by
      }
      return null
    },
    currenPlan () {
      if (this.customer && this.customer.current_plan) {
        return this.customer.current_plan
      }
      return null
    }
  },
  methods: {
    ...mapActions('renewalPlanRequest', {
      setDuration: 'setDuration',
      setNextRenewalDate: 'setNextRenewalDate'
    }),
    getRenewalPlanRequest () {
      this.$isLoading(true)
      this.$axios.post('/api/backend/renewal-plan-request/show', {
        renewal_plan_request_uuid: this.$route.params.renewal_plan_request_uuid
      }).then(({ data }) => {
        if (data.data) {
          this.renewalPlanRequest = data.data
          if (this.renewalPlanRequest && this.renewalPlanRequest.duration_value) {
            this.setDuration(this.renewalPlanRequest.duration_value)
          }
          if (this.renewalPlanRequest && this.renewalPlanRequest.customer) {
            const customer = this.renewalPlanRequest.customer
            if (customer && customer.next_renewal_date && this.$moment(customer.next_renewal_date).isValid()) {
              this.setNextRenewalDate(this.$moment(customer.next_renewal_date).toDate())
            }
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted () {
    this.getRenewalPlanRequest()
  }
}
</script>

<style scoped>

</style>
