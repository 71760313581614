<template>
  <div class="row plan">
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t("plan.boxTitle.planDetail") }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([$Permissions.backend.settings.plan.delete])"
              class="btn btn-danger btn-sm"
              @click="deletePlan"
            >
              <i class="fa fa-trash" /> {{ $t('plan.btn.deletePlan') }}
            </button>
            <router-link
              :to="{
                name: 'edit-plan',
                params:{
                  uuid: $route.params.uuid
                }
              }"
              v-if="can([$Permissions.backend.settings.plan.edit])"
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-edit" /> {{ $t('plan.btn.editPlan') }}
            </router-link>
            <BackButton />
          </div>
        </div>
        <div
          v-if="plan"
          class="box-body"
        >
          <div
            v-if="plan.thumbnail"
            class="row"
          >
            <div class="col-xs-12 col-md-6 col-md-offset-3">
              <div class="thumbnail">
                <img
                  :src="$base_api + '/' + plan.thumbnail"
                  style="width: 120px; height: auto;"
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div
              v-if="plan.services"
              class="col-sm-12"
            >
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.serviceName") }}:
                </label>
                <label class="col-md-9 control-label">
                  {{ plan.services[0].name }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.code") }}:
                </label>
                <label class="col-md-9 control-label">{{ plan.code }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.planName") }}:
                </label>
                <label class="col-md-9 control-label">{{ plan.title }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.short_description") }}:
                </label>
                <label class="col-md-9 control-label">{{ plan.short_description || $t('string.na') }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.description") }}:
                </label>
                <label class="col-md-9 control-label">{{ plan.description || $t('string.na') }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.order") }}:
                </label>
                <label class="col-md-9 control-label">{{ plan.order || $t('string.na') }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.price") }}:
                </label>
                <template v-if="plan.pricing_type">
                  <template v-if="plan.pricing_type.has_price">
                    <label class="col-md-9 control-label">{{ plan.show_price }}
                      {{
                        plan.currency_symbol ? plan.currency_symbol : "$"
                      }}</label>
                  </template>
                  <template v-else>
                    <label class="col-md-9 control-label">{{
                      plan.pricing_type["name_" + $i18n.locale]
                    }}</label>
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("label.createdDate") }}:
                </label>
                <label class="col-md-9 control-label">{{
                  $moment(plan.created_at).format("LL")
                }}</label>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row form-group">
                <label class="col-md-3 control-label">{{ $t("plan.label.feature") }}:
                </label>
              </div>
            </div>
            <div class="col-sm-12">
              <table class="table table-striped">
                <tbody>
                  <template v-for="(item, key) in plan.features">
                    <tr :key="key">
                      <td>{{ item["name_" + $i18n.locale] }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'Show',
  metaInfo () {
    return {
      title: this.$t('page.planDetail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { BackButton },
  data () {
    return {
      plan: {
        name: '',
        price: 0,
        duration_as_second: '',
        description: '',
        subscribable: false,
        service: {},
        created_at: ''
      },
      message: ''
    }
  },
  methods: {
    getPlanByUuid (uuid) {
      this.$axios
        .post('/api/backend/plans/show', {
          uuid: uuid
        })
        .then((response) => {
          this.plan = response.data.data
        })
        .catch((error) => {
          this.$swal({
            type: 'error',
            title: this.$t('label.plan'),
            text: error.response.data.message,
            confirmButtonColor: this.$config.light_gray
          })
        })
    },
    deletePlan () {
      const self = this
      self
        .$swal({
          type: 'warning',
          title: self.$t('swal.delete.title.deletePlan'),
          text: self.$t('swal.delete.text.deletePlan'),
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.$t('global.btn.deleteIt'),
          cancelButtonText: this.$t('global.btn.noDelete')
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .delete('/api/backend/plans/' + this.plan.uuid)
              .then((response) => {
                if (response.data.code === 200) {
                  this.$router.back()
                  self.$swal({
                    title: self.$t('label.plan'),
                    text: self.$t('swal.delete.text.deletedPlan'),
                    type: 'success'
                  })
                }
              })
          }
        })
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.getPlanByUuid(this.$route.params.uuid)
    }
  }
}
</script>

<style scoped></style>
