<template>
  <PlanForm />
</template>

<script>
import PlanForm from './Form.vue'

export default {
  name: 'Create',
  metaInfo () {
    return {
      title: this.$t('page.createNewPlan'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: { PlanForm }
}
</script>

<style scoped>
.plan-description >>> .ql-size-large {
  font-size: 1.5em;
}
</style>
