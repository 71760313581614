import PlanLayout from '@/views/settings/plan/Index'
import PlanIndex from '@/views/settings/plan/List'
import PlanCreate from '@/views/settings/plan/Create'
import PlanEdit from '@/views/settings/plan/Edit'
import PlanShow from '@/views/settings/plan/Show'
import PlanTrash from '@/views/settings/plan/Trash'
import Permissions from '@/permissions'

import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.settings.plan

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'plan',
    breadcrumb: [
      {
        name_en: 'Settings',
        name_km: 'ការកំណត់'
      },
      {
        name_en: 'Plan',
        name_km: 'គម្រោង'
      },
      link
    ]
  }
}

export const planRouters = {
  path: 'plan',
  component: PlanLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'plan'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/plan`,
      name: 'list-plan',
      component: PlanIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-plan',
      component: PlanCreate,
      props: true,
      meta: getMeta([permissions.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:uuid',
      name: 'edit-plan',
      component: PlanEdit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:uuid',
      name: 'show-plan',
      component: PlanShow,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
    {
      path: 'trash',
      name: 'trash-plan',
      component: PlanTrash,
      meta: getMeta([permissions.delete], {
        name_en: 'Trash',
        name_km: 'លុប'
      })
    }
  ]
}
