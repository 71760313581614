<template>
  <button
    class="btn btn-default btn-sm"
    :class="buttonClass"
    @click="back"
  >
    <i class="fa fa-arrow-circle-left" /> {{ $t("global.btn.back") }}
  </button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    buttonClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped></style>
