<template>
  <div
    v-if="item"
    :class="paymentItemClass"
    class="payment-item"
  >
    <div class="item-head">
      <div class="payment-content">
        <p style="margin-bottom: 0">
          <span
            class="margin-r-5"
            style="font-size: 16px"
          >
            <strong>
              {{ $t("payment.label.invoice") }} ( {{ item.receipt_number }} )
            </strong>
          </span>
          <span
            v-if="item.is_paid"
            class="text-success"
            title="finished"
          >
            <i class="fas fa-check-circle" />
          </span>
        </p>
        <p>
          <small>
            <i class="far fa-clock-o margin-r-5" />
            <em>{{
              $moment(item.created_at)
                .locale($i18n.locale)
                .format("dddd, DD MMM YYYY HH:mm:ss A")
            }}</em>
          </small>
        </p>
      </div>
      <div class="payment-button">
        <div class="dropdown text-right">
          <button
            id="dropdownMenu1"
            aria-expanded="true"
            aria-haspopup="true"
            class="btn btn-default dropdown-toggle btn-sm"
            data-toggle="dropdown"
            type="button"
          >
            <i class="fas fa-ellipsis-h" />
          </button>
          <ul
            aria-labelledby="dropdownMenu1"
            class="dropdown-menu dropdown-menu-right"
          >
            <template v-if="!item.is_paid">
              <li>
                <a
                  href="javascript:void(0)"
                  @click="clickPaid"
                >
                  <i class="fas fa-check-circle" /> {{ $t("btn.paid") }}
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  @click="clickEdit"
                >
                  <i class="fas fa-edit" /> {{ $t("global.btn.edit") }}
                </a>
              </li>
            </template>
            <li>
              <a
                href="javascript:void(0)"
                @click="clickDelete"
              >
                <i class="fas fa-trash" /> {{ $t("global.btn.delete") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="clearfix" />
    </div>
    <div class="item-body">
      <div class="row">
        <div
          v-if="item.cashier"
          class="col-xs-12 col-lg-6"
        >
          <p>{{ $t("payment.label.cashier") }}: {{ item.cashier.full_name }}</p>
        </div>
        <div
          v-if="item.paid_at"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.paidDate") }}:
            {{
              $moment(item.paid_at)
                .locale($i18n.locale)
                .format("dddd, DD MMM YYYY")
            }}
          </p>
        </div>
        <div
          v-if="item.total_price"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.totalPrice") }}: {{ item.show_price }}
            {{ item.currency_symbol }}
          </p>
        </div>
        <div
          v-if="item.duration"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.duration") }}: {{ item.duration }}
            {{ $t("string.date") }} {{ item.duration_type }}
          </p>
        </div>
        <div
          v-if="item.start_date"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.startDate") }}:
            {{
              $moment(item.start_date)
                .locale($i18n.locale)
                .format("dddd, DD MMM YYYY")
            }}
          </p>
        </div>
        <div
          v-if="item.end_date"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.endDate") }}:
            {{
              $moment(item.end_date)
                .locale($i18n.locale)
                .format("dddd, DD MMM YYYY")
            }}
          </p>
        </div>
        <div
          v-if="item.payment_option"
          class="col-xs-12 col-lg-6"
        >
          <p>
            {{ $t("payment.label.paymentOption") }}:
            {{ item.payment_option["name_" + $i18n.locale] }}
          </p>
        </div>
        <div
          v-if="item.additionals.length"
          class="col-xs-12"
        >
          <label>{{ $t("label.additionalItem") }}</label>
          <ul class="list-group-custom">
            <template v-for="(subItem, key) in item.additionals">
              <li
                class="list-group-item"
                :key="key"
              >
                <img
                  :alt="subItem['name_' + $i18n.locale]"
                  :src="`${$base_api}/${subItem.icon}`"
                  class="additional-item-icon"
                >
                {{ subItem["name_" + $i18n.locale] }}
              </li>
            </template>
          </ul>
        </div>
        <div class="col-xs-12">
          <p v-if="item.note">
            {{ $t("note.label.note") }}: {{ item.note }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentItem',
  props: {
    title: {
      type: String,
      default: null
    },
    paymentItemClass: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    statuses: {
      type: Object,
      default: null
    }
  },
  methods: {
    clickEdit () {
      this.$emit('clickButtonEdit')
    },

    clickDelete () {
      this.$emit('clickButtonDelete')
    },

    clickPaid () {
      this.$emit('clickButtonPaid')
    }
  }
}
</script>

<style scoped>
.payment-item,
.progress-item {
  background-color: #f3f3f3;
  border-radius: 0.25rem;
  padding: 15px;
  margin-bottom: 10px;
  color: #333;
  width: 100%;
}

.payment-item .payment-content,
.progress-item .progress-content {
  width: 80%;
  float: left;
}

.payment-item .payment-button,
.progress-item .progress-button {
  width: 20%;
  float: right;
}

.payment-item-paid {
  border-left: 5px solid;
  border-color: var(--blue-light);
}

.payment-item-no-paid {
  border-left: 5px solid;
  border-color: var(--red);
}
</style>
