import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import customer from '@/store/modules/customer'
import renewalPlanRequest from '@/store/modules/renewalPlanRequest'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    customer,
    renewalPlanRequest
  }
})
