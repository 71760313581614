<template>
  <div
    v-if="item"
    :class="progressItemClass"
    class="progress-item"
  >
    <div class="item-head">
      <div class="progress-content">
        <p style="margin-bottom: 0">
          <span
            class="margin-r-5"
            style="font-size: 16px"
          ><strong>{{ title }}</strong>
          </span>
          <span
            v-if="item.is_finished"
            class="text-success"
            title="finished"
          >
            <i class="fas fa-check-circle" />
          </span>
        </p>
        <p>
          <small>
            <i class="far fa-clock-o margin-r-5" />
            <em>{{
              $moment(item.created_at)
                .locale($i18n.locale)
                .format("dddd, DD MMM YYYY HH:mm:ss A")
            }}</em>
          </small>
        </p>
      </div>
      <div class="progress-button">
        <div class="dropdown text-right">
          <button
            id="dropdownMenu1"
            aria-expanded="true"
            aria-haspopup="true"
            class="btn btn-default dropdown-toggle btn-sm"
            data-toggle="dropdown"
            type="button"
          >
            <i class="fas fa-ellipsis-h" />
          </button>
          <ul
            aria-labelledby="dropdownMenu1"
            class="dropdown-menu dropdown-menu-right"
          >
            <template
              v-if="item.progress_type === 'Install' && !item.is_finished"
            >
              <li>
                <a
                  href="javascript:void(0)"
                  @click="clickInstalled"
                >
                  <i class="fas fa-check-circle" /> {{ $t("btn.installed") }}
                </a>
              </li>
            </template>
            <li>
              <a
                href="javascript:void(0)"
                @click="clickEdit"
              >
                <i class="fas fa-edit" /> {{ $t("global.btn.edit") }}
              </a>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                @click="clickDelete"
              >
                <i class="fas fa-trash" /> {{ $t("global.btn.delete") }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="clearfix" />
    </div>
    <div class="item-body">
      <p v-if="item.staff">
        <i class="fas fa-user" /> {{ item.staff.full_name }}
      </p>
      <p v-if="item.progress_at">
        <i class="fas fa-calendar-alt" />
        {{
          $moment(item.progress_at)
            .locale($i18n.locale)
            .format("dddd, DD MM YYYY")
        }}
      </p>
      <p v-if="statuses && statuses.hasOwnProperty('item.status')">
        <span class="badge">{{ statuses[item.status] }}</span>
      </p>
      <p v-if="item.note">
        <i class="fas fa-clipboard" /> {{ item.note }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressListItem',
  props: {
    title: {
      type: String,
      default: null
    },
    progressItemClass: {
      type: String,
      default: null
    },
    item: {
      type: Object,
      required: true
    },
    statuses: {
      type: Object,
      default: null
    }
  },
  methods: {
    clickEdit () {
      this.$emit('clickButtonEdit')
    },

    clickDelete () {
      this.$emit('clickButtonDelete')
    },

    clickInstalled () {
      this.$emit('clickButtonInstalled')
    }
  }
}
</script>

<style scoped>
.progress-item {
  background-color: #f3f3f3;
  border-radius: 0.25rem;
  padding: 15px;
  margin-bottom: 10px;
  color: #333;
  width: 100%;
}

.progress-item .progress-content {
  width: 80%;
  float: left;
}

.progress-item .progress-button {
  width: 20%;
  float: right;
}

.progress-item-appointment {
  border-left: 5px solid;
  border-color: var(--blue-light);
}

.progress-item-testing {
  border-left: 5px solid;
  border-color: var(--yellow);
}

.progress-item-approve {
  border-left: 5px solid;
  border-color: var(--green);
}
</style>
