<template>
  <div
    v-if="payment"
    class="row"
  >
    <div
      v-if="payment.receipt_number"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.receiptNumber") }}: {{ payment.receipt_number }}
      </p>
    </div>
    <div
      v-if="payment.created_at"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.createdAt") }}:
        {{
          $moment(payment.created_at)
            .locale($i18n.locale)
            .format("dddd, DD MMM YYYY")
        }}
      </p>
    </div>
    <div
      v-if="payment.total_price"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.totalPrice") }}: {{ payment.show_price }}
        {{ payment.currency_symbol }}
      </p>
    </div>
    <div
      v-if="payment.duration"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.duration") }}: {{ payment.duration }}
        {{ $t("string.date") }} {{ payment.duration_type }}
      </p>
    </div>
    <div
      v-if="payment.start_date"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.startDate") }}:
        {{
          $moment(payment.start_date)
            .locale($i18n.locale)
            .format("dddd, DD MMM YYYY")
        }}
      </p>
    </div>
    <div
      v-if="payment.end_date"
      class="col-xs-12 col-lg-6"
    >
      <p>
        {{ $t("payment.label.endDate") }}:
        {{
          $moment(payment.end_date)
            .locale($i18n.locale)
            .format("dddd, DD MMM YYYY")
        }}
      </p>
    </div>
    <div
      v-if="payment.additionals.length"
      class="col-xs-12"
    >
      <label>{{ $t("label.additionalItem") }}</label>
      <ul class="list-group-custom">
        <template v-for="(subItem, key) in payment.additionals">
          <li
            class="list-group-item"
            :key="key"
          >
            <img
              :alt="subItem['name_' + $i18n.locale]"
              :src="`${$base_api}/${subItem.icon}`"
              class="additional-item-icon"
            >
            {{ subItem["name_" + $i18n.locale] }}
          </li>
        </template>
      </ul>
    </div>
    <div class="col-xs-12">
      <div class="form-group">
        <label
          class="required"
          for="cashier"
        >{{
          $t("payment.label.cashier")
        }}</label>
        <select
          id="cashier"
          v-model="cashier_id"
          :class="{
            'input-danger': checkValidations(validations, 'cashier_id'),
          }"
          class="form-control"
        >
          <option :value="null">
            {{ $t("note.placeholder.selectOne") }}
          </option>
          <template v-if="users && Array.isArray(users) && users.length >0">
            <option
              v-for="(item, key) in users"
              :key="key"
              :value="item.id"
            >
              {{ item.full_name }}
            </option>
          </template>
        </select>
        <span
          v-if="checkValidations(validations, 'cashier_id')"
          class="message-validate"
        >
          {{ validations.cashier_id[0] }}
        </span>
      </div>
      <div class="form-group">
        <label
          class="required"
          for="payment_option"
        >{{
          $t("payment.label.paymentOption")
        }}</label>
        <select
          id="payment_option"
          v-model="payment_option_id"
          :class="{
            'input-danger': checkValidations(validations, 'payment_option_id'),
          }"
          class="form-control"
          data-live-search="true"
          title="Please select a lunch ..."
        >
          <option
            :value="null"
            disabled
          >
            {{ $t("note.placeholder.selectOne") }}
          </option>
          <template v-if="payment_options && Array.isArray(payment_options) && payment_options.length > 0">
            <option
              :value="item.id"
              :key="key"
              v-for="(item, key) in payment_options"
            >
              {{ item["name_" + $i18n.locale] }}
            </option>
          </template>
        </select>
        <span
          v-if="checkValidations(validations, 'payment_option_id')"
          class="message-validate"
        >
          {{ validations.payment_option_id[0] }}
        </span>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group">
        <label
          class="required"
          for="end_date"
        >{{
          $t("payment.label.paidDate")
        }}</label>
        <date-picker
          id="end_date"
          v-model="paid_at"
          :class="{
            'mx-datepicker-error': checkValidations(validations, 'paid_at'),
          }"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('note.placeholder.selectDate')"
          format="YYYY-MM-DD"
          type="date"
          value-type="format"
        />
        <span
          v-if="checkValidations(validations, 'paid_at')"
          class="message-validate"
        >
          {{ validations.paid_at[0] }}
        </span>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group">
        <label for="contact_note">{{ $t("note.label.note") }}</label>
        <textarea
          id="contact_note"
          v-model="note"
          :placeholder="$t('note.placeholder.noteHere')"
          class="form-control"
          cols="30"
          name="note_called"
          rows="10"
        />
      </div>
    </div>
    <div class="col-sm-12 text-right">
      <button
        class="btn btn-primary"
        @click="markAsPaid"
      >
        <i class="fas fa-edit" /> {{ $t("global.btn.update") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MarkAsPaidForm',
  props: {
    payment: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.clearData()
    this.getPaymentOptions()
    this.getUserOptions()
    if (this.payment) {
      this.note = this.payment.note
    }
  },
  data () {
    return {
      payment_options: [],
      users: [],
      validations: null,
      cashier_id: null,
      payment_option_id: null,
      note: null,
      paid_at: null
    }
  },
  methods: {
    clearData () {
      this.cashier_id = null
      this.payment_option_id = null
      this.note = null
      this.paid_at = null
    },
    getUserOptions () {
      this.$axios
        .post('/api/backend/user/get-options')
        .then((res) => {
          this.users = res.data.data
        })
    },
    getPaymentOptions () {
      this.$axios
        .post('/api/backend/payment-option/get-options')
        .then((res) => {
          this.payment_options = res.data.data
        })
    },
    markAsPaid () {
      this.validations = null
      this.$axios.post('/api/backend/payment/mark-as-paid', {
        uuid: this.payment.uuid,
        cashier_id: this.cashier_id,
        payment_option_id: this.payment_option_id,
        paid_at: this.paid_at,
        note: this.note
      }).then(() => {
        this.clearData()
        this.$emit('callBackFunction')
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      })
    }
  }
}
</script>

<style scoped></style>
