<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <template v-if="service_uuid">
        <h3 class="box-title">
          {{ $t("service.boxTitle.editService") }}
        </h3>
      </template>
      <template v-else>
        <h3 class="box-title">
          {{ $t("service.boxTitle.createService") }}
        </h3>
      </template>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="{ 'has-error': validations.name }"
          class="form-group"
        >
          <label
            class="col-sm-3 control-label required"
            for="serviceName"
          >{{
            $t("service.label.serviceName")
          }}</label>
          <div class="col-sm-9">
            <input
              id="serviceName"
              v-model="service.name"
              :calss="{ 'input-danger': validations.name }"
              :placeholder="$t('service.placeholder.inputTheServiceName')"
              class="form-control"
              type="text"
            >
            <span
              v-if="validations.name"
              class="message-validate"
            >{{
              validations["name"][0]
            }}</span>
          </div>
        </div>
        <div
          :class="{'has-error' : validations.short_cut}"
          class="form-group"
        >
          <template v-if="$route.name === 'create-service'">
            <label
              class="col-sm-3 control-label required"
              for="serviceShortCut"
            >
              {{ $t("service.label.shortcode") }}
            </label>
            <div class="col-sm-9">
              <input
                id="serviceShortCut"
                v-model="service.short_cut"
                :calss="{ 'input-danger': validations.short_cut}"
                :placeholder="$t('service.placeholder.inputTheServiceShortCode')"
                class="form-control"
                type="text"
              >
              <span
                v-if="validations.short_cut"
                class="message-validate"
              >{{ validations.short_cut[0] }}</span>
            </div>
          </template>
          <template v-else>
            <label
              class="col-sm-3 control-label required"
              for="serviceShortCut"
            >
              {{ $t("service.label.shortcode") }}
            </label>
            <div class="col-sm-9">
              <label>{{ service.short_cut }}</label>
            </div>
          </template>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">{{ $t("service.label.serviceBaseUrl") }}</label>
          <div class="col-sm-9">
            <input
              v-model="service.base_url"
              class="form-control"
              placeholder="e.g. https://example-url.com"
              type="text"
            >
          </div>
        </div>
        <div class="form-group">
          <label
            class="col-sm-3 control-label"
            for="description"
          >{{ $t("label.description") }}</label>
          <div class="col-sm-9">
            <textarea
              id="description"
              v-model="service.description"
              :placeholder="$t('service.placeholder.inputTheServiceDescription')"
              class="form-control"
              rows="10"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-3 col-sm-9 tw-space-x-2">
            <template v-if="service_uuid">
              <button
                class="btn btn-success btn-sm"
                @click="updateService(service_uuid)"
              >
                {{ $t("global.btn.save") }}
              </button>
            </template>
            <template v-else>
              <button
                class="btn btn-success btn-sm"
                @click="storeService"
              >
                {{ $t("global.btn.submit") }}
              </button>
            </template>
            <back-button />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'Create',
  components: { BackButton },
  data () {
    return {
      service_uuid: '',
      service: {
        name: '',
        short_cut: '',
        description: '',
        base_url: ''
      },
      validations: []
    }
  },
  methods: {
    getServiceInfo (uuid) {
      this.$isLoading(true)
      this.$axios.post('/api/backend/services/show', {
        uuid: uuid
      }).then(({ data }) => {
        if (data.data) {
          this.service = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    storeService () {
      this.$isLoading(true)
      this.validations = {}
      this.$axios.post('/api/backend/services/store', this.service)
        .then(() => {
          this.$router.push({ name: 'list-service' })
          this.$toastr(
            'success',
            this.$t('swal.success.text.createService'),
            this.$t('label.service')
          )
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    updateService () {
      this.$isLoading(true)
      this.validations = {}

      this.$axios.post('/api/backend/services/update', this.service)
        .then(() => {
          this.$router.push({ name: 'list-service' })
          this.$toastr(
            'success',
            this.$t('swal.success.text.createService'),
            this.$t('label.service')
          )
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.service_uuid = this.$route.params.uuid
      this.getServiceInfo(this.service_uuid)
    }
  }
}
</script>

<style scoped></style>
