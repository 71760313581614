<template>
  <div
    id="approveRequestModal"
    aria-labelledby="approveRequestModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="approveRequestModalLabel"
            class="modal-title"
          >
            {{ $t('string.approveRequest') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label :for="'reason'">{{ $t('string.reason') }}</label>
            <textarea
              id="reason"
              v-model="reason"
              :placeholder="$t('string.pleaseWriteTheReasonHere')"
              class="form-control"
              rows="5"
            />
          </div>
          <div class="form-group">
            <label for="send_email">
              <input
                id="send_email"
                v-model="send_email"
                type="checkbox"
              > {{ $t('string.sendAnEmail') }}
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="submit"
          >
            {{ $t('button.submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApproveRequestModal',
  data () {
    return {
      reason: null,
      send_email: false,
      validations: {}
    }
  },
  methods: {
    submit () {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post('/api/backend/change-plan-request/approve', {
        request_change_plan_uuid: this.$route.params.change_plan_request_uuid,
        reason: this.reason
      }).then(({ data }) => {
        if (data.data) {
          this.reason = null
          this.send_email = false
          this.$refs.close.click()
          this.$toastr('success', this.$t('string.requestHasBeenRejected'), this.$t('string.rejectRequest'))
          this.$emit('getChangePlanRequest')
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
