import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

export const genderRouters = {
  path: 'gender',
  component: () => import('@/views/settings/gender/Index'),
  redirect: 'gender',
  meta: {
    permissions: [Permissions.backend.settings.gender.manage],
    groups: 'gender'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/gender`,
      name: 'list-gender',
      component: () => import('@/views/settings/gender/List'),
      meta: {
        permissions: [Permissions.backend.settings.gender.list],
        groups: 'gender',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Gender',
            name_km: 'ភេទ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    }
  ]
}
