<template>
  <li
    class="dropdown user user-menu"
    v-if="user"
  >
    <a
      href="#"
      class="dropdown-toggle"
      data-toggle="dropdown"
    >
      <template v-if="user.avatar_location">
        <img
          :src="`${getAvatar ? getSrc(getAvatar) : avatar}`"
          class="user-image"
          alt="User Image"
        >
      </template>
      <template v-else>
        <img
          :src="avatar"
          class="user-image"
          alt="User Image"
        >
      </template>
      <span class="hidden-xs">{{ fullName }}</span>
    </a>
    <ul class="dropdown-menu">
      <li class="user-header">
        <img
          :src="`${getAvatar ? getSrc(getAvatar) : avatar}`"
          class="img-circle"
          alt="User Image"
        >
        <p>
          {{ user.username }}
          <template v-for="(role, key) in user.roles">
            <span :key="key">{{ role }}</span>
          </template>
          <small>{{ $t("label.MemberSince") }}
            {{ $moment(user.created_at).format("ll") }}</small>
        </p>
      </li>
      <li class="user-footer">
        <div class="pull-left">
          <router-link
            :to="{ name: 'profile' }"
            class="btn btn-default btn-flat"
          >
            {{ $t("label.profile") }}
          </router-link>
        </div>
        <div class="pull-right">
          <button
            @click="signOut"
            class="btn btn-default btn-flat"
          >
            {{ $t("label.logout") }}
          </button>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'HeaderUser',
  computed: {
    ...mapState({
      user: (state) => state.user.data
    }),
    ...mapGetters('user', {
      getAvatar: 'avatar'
    }),
    fullName () {
      if (this.user) {
        return `${this.user.first_name} ${this.user.last_name}`
      }
      return this.$t('string.unknown')
    }
  },
  methods: {
    signOut () {
      this.$isLoading(true)
      this.$axios.get('/api/auth/logout')
        .finally(() => {
          this.$store.dispatch('user/clearUser')
          localStorage.removeItem(this.$token)
          localStorage.removeItem(this.$refreshToken)
          this.$router.push({ name: 'login' })
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
