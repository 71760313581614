<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('menu.dashboard') }}
      </h3>
    </div>
    <div class="box-body">
      <h3>{{ $t('string.welcomeTo') }} {{ config.VUE_APP_NAME }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data () {
    return {
      config: process.env
    }
  }
}
</script>

<style scoped>

</style>
