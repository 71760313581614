<template>
  <table
    id="customer-table"
    class="table table-bordered table-hover"
  >
    <thead>
      <tr>
        <th>{{ $t("table.customerName") }}</th>
        <th>{{ $t("table.currentPlan") }}</th>
        <th>{{ $t("table.nextRenewalDate") }}</th>
        <th>{{ $t("table.nextRenewalPrice") }}</th>
        <th>{{ $t("table.createdDate") }}</th>
        <th>{{ $t("table.actions") }}</th>
      </tr>
    </thead>
  </table>
</template>

<script>
export default {
  name: 'CustomerList',
  data () {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.destroy()
      this.oTable.clear()
      this.oTable = this.dataTable()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    dataTable () {
      const self = this
      return $('#customer-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/services/customers',
          type: 'POST',
          data: (d) => {
            d.service_uuid = self.$route.params.uuid
          }
        },
        columns: [
          { data: 'name', name: 'name', title: this.$t('table.name') },
          { data: 'current_plan.title', name: 'currentPlan.title', title: this.$t('table.currentPlan') },
          { data: 'next_renewal_date', name: 'next_renewal_date', title: this.$t('table.nextRenewalDate') },
          { data: 'next_renewal_price', name: 'next_renewal_price', title: this.$t('table.nextRenewalPrice') },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('table.createdAt'),
            searchable: false
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('table.actions'),
            searchable: false,
            orderable: false,
            width: '10%'
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        }
      })
    },
    buttonAction () {
      const vm = this
      $(document).off('click', '.btn-show')

      $(document).on('click', '.btn-show', function (e) {
        e.preventDefault()
        vm.$router.push({
          name: 'show-customer',
          params: {
            customer_uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  },
  mounted () {
    this.oTable = this.dataTable()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
