<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t("service.boxTitle.listOfService") }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can([$Permissions.backend.service.listTrashed])"
          class="btn btn-default btn-sm"
          @click="$router.push({name: 'trash-service'})"
        >
          <i class="fa fa-trash" /> {{ $t('global.btn.trash') }}
        </button>
        <router-link
          v-if="can([$Permissions.backend.service.store])"
          :to="{ name: 'create-service' }"
          class="btn btn-success btn-sm"
        >
          <i class="fa fa-plus-circle" />
          {{ $t("service.btn.createService") }}
        </router-link>
      </div>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <table
            id="service-table"
            class="table table-bordered table-hover"
          >
            <thead>
              <tr>
                <th>{{ $t("service.table.name") }}</th>
                <th>{{ $t("service.table.shortCode") }}</th>
                <th>{{ $t("service.table.description") }}</th>
                <th>{{ $t("service.table.customersCount") }}</th>
                <th>{{ $t("service.table.createdDate") }}</th>
                <th>{{ $t("service.table.actions") }}</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  metaInfo () {
    return {
      title: this.$t('page.serviceList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  data () {
    return {
      oTable: null,
      services: []
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.getServices()
      this.oTable.draw(true)
    },
    oTable () {
      this.buttonAction()
    }
  },
  methods: {
    getServices () {
      const self = this
      return $('#service-table').DataTable({
        processing: true,
        serverSide: true,
        stateSave: true,
        ajax: {
          url: self.$base_api + '/api/backend/services/datatable',
          type: 'POST'
        },
        columns: [
          { data: 'name', name: 'name', title: this.$t('service.table.name') },
          {
            data: 'short_cut',
            name: 'short_cut',
            title: this.$t('service.table.shortCode')
          },
          {
            data: 'description',
            name: 'description',
            title: this.$t('service.table.description'),
            searchable: false,
            orderable: false
          },
          {
            data: 'customers_count',
            name: 'customers_count',
            title: this.$t('service.table.customersCount'),
            searchable: false,
            orderable: false
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: this.$t('service.table.createdDate'),
            searchable: false
          },
          {
            data: 'actions',
            name: 'actions',
            title: this.$t('service.table.actions'),
            searchable: false,
            orderable: false
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [[4, 'desc']]
      })
    },
    delete (uuid) {
      const self = this
      self.$swal({
        type: 'warning',
        title: this.$t('string.areYouSure?'),
        text: this.$t('string.youWontBeAbleToRevertThis!'),
        showCancelButton: true,
        confirmButtonText: this.$t('global.btn.deleteIt'),
        cancelButtonText: this.$t('global.btn.noDelete')
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete('/api/backend/services/' + uuid)
            .then((response) => {
              if (response.data.code === 200) {
                self.$swal({
                  title: self.$t('label.service'),
                  text: self.$t('swal.delete.text.deletedService'),
                  type: 'success'
                })
                this.oTable.draw(true)
              }
            })
        }
      })
    },
    buttonAction () {
      const self = this
      $(document).off('click', '.btn-show-service')

      $(document).on('click', '.btn-show-service', function (e) {
        e.preventDefault()
        const uuid = $(this).attr('data-uuid')
        self.$router.push({ name: 'show-service', params: { uuid: uuid } })
      })
    }
  },
  mounted () {
    this.oTable = this.getServices()
    this.buttonAction()
  }
}
</script>

<style scoped>

</style>
