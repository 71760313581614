<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <NavbarUser />
      <ul class="sidebar-menu">
        <template v-for="(menu, key) in menus">
          <template v-if="menu.route === null && menu.children === null">
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <li
                class="header"
                :key="key"
              >
                {{ menu.label }}
              </li>
            </template>
          </template>
          <template v-else-if="menu.route !== null && menu.children === null">
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <li
                :class="{active: $route.meta.groups === menu.route.groups}"
                :key="key"
              >
                <router-link :to="menu.route">
                  <i :class="menu.icon" /> <span>{{ menu.label }}</span>
                </router-link>
              </li>
            </template>
          </template>
          <template v-else>
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <TreeMenu
                :menu="menu"
                :key="key"
              />
            </template>
          </template>
        </template>
      </ul>
    </section>
  </aside>
</template>

<script>
import TreeMenu from '@/components/TreeMenu'
import NavbarUser from '@/components/NavbarUser'

export default {
  name: 'Navbar',
  components: {
    TreeMenu,
    NavbarUser
  },
  data () {
    return {
      locale: this.$i18n.locale,
      appName: process.env.VUE_APP_NAME
    }
  },
  computed: {
    menus () {
      return [
        {
          label: this.$t('nav.dashboard'),
          route: null,
          icon: null,
          children: null,
          permissions: [
            this.$Permissions.backend.dashboard.manage,
            this.$Permissions.backend.clientRequest.manage,
            this.$Permissions.backend.payment.manage,
            this.$Permissions.backend.service.manage,
            this.$Permissions.backend.customer.manage,
            this.$Permissions.backend.changePlanRequest.manage,
            this.$Permissions.backend.renewalPlanRequest.manage
          ]
        },
        {
          label: this.$t('nav.dashboard'),
          route: { name: 'dashboard', groups: 'dashboard' },
          icon: 'fa fa-home',
          children: null,
          permissions: [
            this.$Permissions.backend.manage,
            this.$Permissions.backend.dashboard.manage
          ]
        },
        {
          label: this.$t('nav.clientRequest'),
          route: { name: 'client-request-index', groups: 'clientRequest' },
          icon: 'fa fa-address-book',
          children: null,
          permissions: [this.$Permissions.backend.clientRequest.manage]
        },
        {
          label: this.$t('nav.payment'),
          route: { name: 'payment-index', groups: 'payment' },
          icon: 'fa fa-file-invoice',
          children: null,
          permissions: [this.$Permissions.backend.payment.manage]
        },
        {
          label: this.$t('nav.service'),
          route: { name: 'list-service', groups: 'service' },
          icon: 'fa fa-book',
          children: null,
          permissions: [this.$Permissions.backend.service.manage]
        },
        {
          label: this.$t('nav.customer'),
          route: { name: 'list-customer', groups: 'customer' },
          icon: 'fa fa-users',
          children: null,
          permissions: [this.$Permissions.backend.customer.manage]
        },
        {
          label: this.$t('nav.changePlanRequest'),
          route: { name: 'list-change-plan-request', groups: 'changePlanRequest' },
          icon: 'fa fa-retweet',
          children: null,
          permissions: [this.$Permissions.backend.changePlanRequest.manage]
        },
        {
          label: this.$t('nav.renewalPlanRequest'),
          route: { name: 'list-renewal-plan-request', groups: 'renewalPlanRequest' },
          icon: 'fa fa-refresh',
          children: null,
          permissions: [this.$Permissions.backend.renewalPlanRequest.manage]
        },
        {
          label: this.$t('nav.system'),
          route: null,
          icon: null,
          children: null,
          permissions: [
            this.$Permissions.backend.settings.manage,
            this.$Permissions.backend.settings.plan.manage,
            this.$Permissions.backend.settings.feature.manage,
            this.$Permissions.backend.settings.additionalDevice.manage,
            this.$Permissions.backend.settings.gender.manage
          ]
        },
        {
          label: this.$t('nav.setting'),
          route: null,
          icon: 'fa fa-cogs',
          permissions: [
            this.$Permissions.backend.settings.manage,
            this.$Permissions.backend.settings.plan.manage,
            this.$Permissions.backend.settings.feature.manage,
            this.$Permissions.backend.settings.additionalDevice.manage,
            this.$Permissions.backend.settings.gender.manage
          ],
          children: [
            {
              label: this.$t('nav.plan'),
              route: { name: 'list-plan', groups: 'plan' },
              icon: 'fa fa-paper-plane',
              children: null,
              permissions: [this.$Permissions.backend.settings.plan.manage]
            },
            {
              label: this.$t('nav.feature'),
              route: { name: 'feature-index', groups: 'feature' },
              icon: 'fa fa-feather',
              children: null,
              permissions: [this.$Permissions.backend.settings.feature.manage]
            },
            {
              label: this.$t('nav.shopType'),
              route: { name: 'list-shop-type', groups: 'shopType' },
              icon: 'fa fa-shapes',
              children: null,
              permissions: [this.$Permissions.backend.settings.shopType.manage]
            },
            {
              label: this.$t('nav.additionalDevice'),
              route: { name: 'list-additional-device', groups: 'additionalDevice' },
              icon: 'fa fa-desktop',
              children: null,
              permissions: [this.$Permissions.backend.settings.additionalDevice.manage]
            },
            {
              label: this.$t('menu.gender'),
              route: { name: 'list-gender', groups: 'gender' },
              permissions: [this.$Permissions.backend.settings.gender.manage]
            },
            {
              label: this.$t('logs_viewer.menu'),
              route: { name: 'list-logs-viewer', groups: 'logs-viewer' },
              permissions: [this.$Permissions.backend.settings.logsViewer.manage]
            }
          ]
        },
        {
          label: this.$t('menu.administration'),
          route: null,
          icon: 'fa fa-code',
          permissions: [this.$Permissions.backend.administration.manage],
          children: [
            {
              label: this.$t('menu.user'),
              route: { name: 'list-user', groups: 'user' },
              permissions: [this.$Permissions.backend.administration.user.manage]
            },
            {
              label: this.$t('menu.role'),
              route: { name: 'list-role', groups: 'role' },
              permissions: [this.$Permissions.backend.administration.role.manage]
            },
            {
              label: this.$t('menu.permission'),
              route: { name: 'list-permission', groups: 'permission' },
              permissions: [this.$Permissions.backend.administration.permission.manage]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped></style>
