export const datepickerLocale = {
  km: {
    lang: {
      months: [
        'មករា',
        'កុម្ភៈ',
        'មីនា',
        'មេសា',
        'ឧសភា',
        'មិថុនា',
        'កក្កដា',
        'សីហា',
        'កញ្ញា',
        'តុលា',
        'វិច្ឆិកា',
        'ធ្នូ'
      ],
      monthsShort: [
        'មករា',
        'កុម្ភៈ',
        'មីនា',
        'មេសា',
        'ឧសភា',
        'មិថុនា',
        'កក្កដា',
        'សីហា',
        'កញ្ញា',
        'តុលា',
        'វិច្ឆិកា',
        'ធ្នូ'
      ],
      days: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
      weekdays: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
      weekdaysShort: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
      weekdaysMin: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស']
    }
  },
  en: {
    lang: 'en'
  }
}
