<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <template>
        <h3 class="box-title">
          {{ title }}
        </h3>
      </template>
      <div class="box-tools">
        <BackButton button-class="btn-sm" />
      </div>
    </div>
    <div class="form">
      <div class="box-body">
        <div class="form-horizontal">
          <div
            :class="{'has-error' : validations.name_km}"
            class="form-group"
          >
            <label
              class="col-sm-3 control-label required"
              for="name_km"
            >
              {{ $t("table.name_km") }}
            </label>
            <div class="col-sm-9">
              <input
                id="name_km"
                v-model="shop_type.name_km"
                :placeholder="$t('table.name_km')"
                class="form-control"
                type="text"
              >
              <span
                v-if="validations.name_km"
                class="message-validate"
              >{{ validations.name_km[0] }}</span>
            </div>
          </div>
          <div
            :class="{'has-error' : validations.name_en}"
            class="form-group"
          >
            <label
              class="col-sm-3 control-label required"
              for="name_en"
            >
              {{ $t("table.name_en") }}
            </label>
            <div class="col-sm-9">
              <input
                id="name_en"
                v-model="shop_type.name_en"
                :placeholder="$t('table.name_en')"
                class="form-control"
                type="text"
              >
              <span
                v-if="validations.name_en"
                class="message-validate"
              >{{ validations.name_en[0] }}</span>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-3 col-sm-9">
              <template v-if="shop_type.uuid">
                <button
                  class="btn btn-success"
                  type="button"
                  @click="store"
                >
                  {{ $t("global.btn.save") }}
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-success"
                  @click="store"
                >
                  {{ $t("global.btn.submit") }}
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton'

export default {
  name: 'ShopTypeForm',
  components: { BackButton },
  data () {
    return {
      validations: {},
      shop_type: {
        uuid: null,
        name_km: null,
        name_en: null,
        active: null
      }
    }
  },
  computed: {
    title () {
      if (this.$route.params.uuid) {
        return this.$t('shopType.boxTitle.editShopType')
      } else {
        return this.$t('shopType.boxTitle.createShopType')
      }
    }
  },
  mounted () {
    if (this.$route.params.uuid) {
      this.getShopTypeShow()
    }
  },

  methods: {
    getShopTypeShow () {
      this.$axios.post('/api/backend/shop-type/show', {
        uuid: this.$route.params.uuid
      }).then(({ data }) => {
        if (data.data) {
          this.shop_type = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },

    store () {
      this.$isLoading(true)
      this.validations = {}
      this.$axios.post('/api/backend/shop-type/store', this.shop_type)
        .then((data) => {
          if (data.data) {
            this.$router.push({ name: 'list-shop-type' })
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
