import ShopTypeLayout from '@/views/settings/shopType/Index'
import ShopTypeIndex from '@/views/settings/shopType/List'
import ShopTypeCreate from '@/views/settings/shopType/Create'
import ShopTypeEdit from '@/views/settings/shopType/Edit'
import Permissions from '@/permissions'
import { useBaseRouteModules } from '@/composables/useTrailingSlash'
const { baseSettingModuleUrl } = useBaseRouteModules()

const permissions = Permissions.backend.settings.shopType

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'shopType',
    breadcrumb: [
      {
        name_en: 'Settings',
        name_km: 'ការកំណត់'
      },
      {
        name_en: 'Shop type',
        name_km: 'ប្រភេទហាង'
      },
      link
    ]
  }
}

export const shopTypeRouters = {
  path: 'shop-type',
  component: ShopTypeLayout,
  redirect: '',
  meta: {
    permissions: [permissions.manage],
    groups: 'shopType'
  },
  children: [
    {
      path: `${baseSettingModuleUrl}/shop-type`,
      name: 'list-shop-type',
      component: ShopTypeIndex,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-shop-type',
      component: ShopTypeCreate,
      meta: getMeta([permissions.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:uuid',
      name: 'edit-shop-type',
      component: ShopTypeEdit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    }
  ]
}
