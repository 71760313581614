import Primary from '@/layouts/Primary'
import { serviceRouters } from '@/router/serviceRouters'
import { paymentRouters } from '@/router/paymentRouters'
import { clientRequestRouters } from '@/router/clientRequestRouters'
import { customerRouters } from '@/router/customerRouters'
import { dashboardRouters } from '@/router/dashboardRouters'
import { changePlanRequestRouters } from '@/router/changePlanRequest'
import { renewalPlanRequestRouters } from '@/router/renewalPlanRequest'
import { administrationRouters } from '@/router/administration'
import { settingsRouters } from '@/router/settings'
import { accountRouters } from '@/router/accountRouters'

export const primaryRouter = {
  path: '/admin',
  name: 'primary',
  component: Primary,
  redirect: 'dashboard',
  children: [
    dashboardRouters,
    clientRequestRouters,
    paymentRouters,
    serviceRouters,
    customerRouters,
    changePlanRequestRouters,
    renewalPlanRequestRouters,
    administrationRouters,
    settingsRouters,
    accountRouters
  ]
}
