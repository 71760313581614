<template>
  <div class="row">
    <div class="col-md-4">
      <ServiceDetail />
    </div>
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.customerList') }}
          </h3>
        </div>
        <div class="box-body">
          <CustomerList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceDetail from '@/views/service/_components/ServiceDetail'
import CustomerList from '@/views/service/_components/CustomerList'

export default {
  name: 'Show',
  components: { CustomerList, ServiceDetail },
  metaInfo () {
    return {
      title: this.$t('page.serviceDetail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped>

</style>
