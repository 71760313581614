<template>
  <div
    id="show-client-request"
    ref="section"
    class="row"
  >
    <div class="col-md-6">
      <div class="client-request">
        <SectionClient
          :client="client_request"
          :title="$t('client_request.boxTitle.clientRequestDetail')"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t(form_title) }}
          </h3>
          <div class="box-tools">
            <button
              v-if="show_form"
              class="btn btn-default btn-sm"
              @click="cancelNote"
            >
              <i class="fas fa-times-circle" /> {{ $t("btn.cancel") }}
            </button>
            <template v-else>
              <button
                class="btn btn-primary btn-sm"
                @click="
                  showHideForm(true, 'contact', $t('note.label.addContactNote'))
                "
              >
                <i class="fas fa-plus-circle" />
                {{ $t("note.label.contactNote") }}
              </button>
              <template
                v-if="
                  !progresses.some((item) => item.progress_type === 'Install')
                "
              >
                <button
                  class="btn btn-primary btn-sm"
                  @click="
                    showHideForm(true, 'install', $t('note.label.addAppointment'))
                  "
                >
                  <i class="fas fa-plus-circle" />
                  {{ $t("note.label.appointment") }}
                </button>
              </template>
            </template>
          </div>
        </div>
        <div class="box-body">
          <template v-if="show_form">
            <div class="row">
              <template v-if="type">
                <template v-if="type === 'contact'">
                  <ProgressContact
                    :client-progress="client_progress"
                    :client-request-uuid="client_request.uuid"
                    @callBackFunction="getClientRequest"
                  />
                </template>
                <template v-else-if="type === 'install'">
                  <AppointmentInstallation
                    :client-progress="client_progress"
                    :client-request-uuid="client_request.uuid"
                    @callBackFunction="getClientRequest"
                  />
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <template v-if="progresses.length">
              <template v-for="(item, key) in progresses">
                <div
                  v-if="item.progress_type === 'Install'"
                  :key="key"
                >
                  <progress-list-item
                    :item="item"
                    :statuses="statuses"
                    progress-item-class="progress-item-appointment"
                    :title="$t('note.label.appointmentInstall')"
                    @clickButtonDelete="deleteClientProgress(item.uuid)"
                    @clickButtonEdit="
                      showClientProgress(
                        item.uuid,
                        'install',
                        $t('note.label.editAppointment')
                      )
                    "
                    @clickButtonInstalled="markAsInstalled(item.uuid)"
                  />
                </div>
                <template v-else-if="item.progress_type === 'Contact'">
                  <template v-if="item.status === 'approved'">
                    <ProgressListItem
                      :key="key"
                      :item="item"
                      :statuses="statuses"
                      progress-item-class="progress-item-approve"
                      :title="$t('note.label.contactClientApproved')"
                      @clickButtonDelete="deleteClientProgress(item.uuid)"
                      @clickButtonEdit="
                        showClientProgress(
                          item.uuid,
                          'contact',
                          $t('note.label.editContactNote')
                        )
                      "
                    />
                  </template>
                  <template v-else>
                    <ProgressListItem
                      :key="key"
                      :item="item"
                      :statuses="statuses"
                      progress-item-class="progress-item-testing"
                      :title="$t('note.label.contactClientTest')"
                      @clickButtonDelete="deleteClientProgress(item.uuid)"
                      @clickButtonEdit="
                        showClientProgress(
                          item.uuid,
                          'contact',
                          $t('note.label.editContactNote')
                        )
                      "
                    />
                  </template>
                </template>
              </template>
              <paginate
                v-if="page.total_pages > 1"
                v-model="page.current_page"
                :click-handler="getProgressByClient"
                :container-class="'pagination'"
                :margin-pages="1"
                :next-class="'page-item'"
                :next-link-class="'page-link'"
                :next-text="'Next'"
                :page-class="'page-item'"
                :page-count="page.total_pages"
                :page-link-class="'page-link'"
                :page-range="1"
                :prev-class="'page-item'"
                :prev-link-class="'page-link'"
                :prev-text="'Prev'"
                class="float-right"
              />
            </template>
            <template v-else>
              <img-no-data />
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressListItem from '@/views/clientRequest/_components/ProgressListItem'
import ProgressContact from '@/views/clientRequest/_components/ProgressContact'
import AppointmentInstallation from '@/views/clientRequest/_components/AppointmentInstallation'
import SectionClient from '@/components/SectionClient'
import ImgNoData from '@/components/ImgNoData'

export default {
  name: 'Show',
  metaInfo () {
    return {
      title: this.$t('string.showClientRequestProgress'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  },
  components: {
    ImgNoData,
    SectionClient,
    AppointmentInstallation,
    ProgressContact,
    ProgressListItem
  },
  data () {
    return {
      client_request: null,
      progresses: [],
      number_per_page: 8,
      page: {
        total_pages: 0,
        current_page: 1,
        per_page: 10,
        total_records: 0
      },

      statuses: null,
      show_form: false,
      form_title: 'label.progress',
      type: null,
      client_progress: null
    }
  },
  mounted () {
    this.getClientRequest()
    this.getStatusOptions()
  },
  methods: {
    getStatusOptions () {
      this.$axios
        .get('/api/backend/registration-request/get-options')
        .then((res) => {
          this.statuses = res.data.data
        })
    },
    showHideForm (show_form, type, title, is_edit = false) {
      if (!is_edit) this.client_progress = null
      this.show_form = show_form
      this.type = type
      this.form_title = title
    },
    getProgressByClient (page = 1) {
      this.page.current_page = page
      this.$axios
        .post(
          '/api/backend/client-progress/get-progress-by-client',
          {
            client_request_uuid: this.$route.params.uuid,
            page: this.page.current_page,
            number_per_page: this.number_per_page
          }
        )
        .then((res) => {
          this.progresses = res.data.data
          this.page = res.data
        })
    },
    getClientRequest () {
      this.cancelNote()
      this.$axios
        .post('/api/backend/registration-request/show', {
          uuid: this.$route.params.uuid
        })
        .then((res) => {
          this.client_request = res.data.data
          this.getProgressByClient(1)
        })
    },
    cancelNote () {
      this.show_form = false
      this.type = null
      this.form_title = 'label.progress'
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    showClientProgress (uuid, type, title = '') {
      this.scrollToTop()
      this.$axios
        .post('/api/backend/client-progress/show', {
          uuid: uuid
        })
        .then((res) => {
          this.client_progress = res.data.data
          this.showHideForm(true, type, title, true)
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    deleteClientProgress (uuid) {
      const self = this
      self
        .$swal({
          type: 'warning',
          title: self.$t('swal.delete.title.deleteProgress'),
          text: self.$t('swal.delete.text.deleteProgress'),
          showCancelButton: true,
          confirmButtonColor: '#a5a6ac',
          cancelButtonColor: '#3085d6',
          confirmButtonText: this.$t('global.btn.deleteIt'),
          cancelButtonText: this.$t('global.btn.noDelete')
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('/api/backend/client-progress/delete', {
                uuid: uuid
              })
              .then(() => {
                self.$swal({
                  title: self.$t('label.progress'),
                  text: self.$t('swal.delete.text.deletedProgress'),
                  type: 'success'
                })
                this.getClientRequest()
              })
              .catch((error) => {
                this.onResponseError(error)
              })
          }
        })
    },
    markAsInstalled (client_progress_uuid) {
      this.$axios
        .post('/api/backend/client-progress/mark-as-installed', {
          client_progress_uuid: client_progress_uuid,
          client_request_uuid: this.client_request.uuid
        })
        .then(() => {
          this.getClientRequest()
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    }
  }
}
</script>

<style scoped></style>
